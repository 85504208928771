import { CircularProgress, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction, useState } from "react";
import { PATH_CONNECTOR_REDIRECT_URI } from "../../Routes/paths";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useConnectorsPost } from "../../../queries/connector";
import { Connector, ConnectorType } from "../../../types/Connector";
import { useTranslation } from "react-i18next";
import {
  connectorBoxImageStyles,
  connectorBoxStyles,
} from "../../ConnectorsByCategoryV2";

type NativeFivetranConnectorProps = {
  connector?: Connector;
  connectorType: ConnectorType;
  setConnectionError: Dispatch<SetStateAction<boolean>>;
  overview?: Boolean;
  isConnected?: Boolean;
};

const NativeFivetranConnector = ({
  connector,
  connectorType,
  setConnectionError,
  overview,
  isConnected,
}: NativeFivetranConnectorProps) => {
  const { t } = useTranslation();
  const [connecting, setConnecting] = useState(false);
  const [, setConnectorId] = useLocalStorage<string | null>(
    "connectorRedirectUriConnectorId",
    null
  );

  const { mutateAsync: createConnectorMutation } = useConnectorsPost();

  const createConnector = async () => {
    await createConnectorMutation(
      { connectorType: connectorType["@id"]! },
      {
        onError: () => {
          setConnecting(false);
          setConnectionError(true);
        },
        onSuccess: (data) => {
          if (
            data.configurationData &&
            "fivetran_storage" in data.configurationData &&
            data.configurationData.fivetran_storage?.card_token?.token
          ) {
            setConnectorId(data.id!);
            window.location.href = `https://fivetran.com/connect-card/setup?redirect_uri=${encodeURIComponent(
              `${window.origin}${PATH_CONNECTOR_REDIRECT_URI.native}`
            )}&auth=${
              data.configurationData.fivetran_storage.card_token.token
            }&hide_setup_guide=true`;
            return;
          } else {
            setConnecting(false);
          }
        },
      }
    );
  };

  return (
    <Box
      sx={connectorBoxStyles}
      onClick={async () => {
        setConnecting(true);
        await createConnector();
      }}
    >
      {connecting ? (
        <CircularProgress
          size="2rem"
          sx={{ color: (theme) => theme.palette.primary.main }}
        />
      ) : (
        <>
          {overview ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "80%" }}
            >
              <Box
                component="img"
                sx={{
                  ...connectorBoxImageStyles,
                  maxWidth: "156px",
                  maxHeight: "67px",
                }}
                src={`/images/connector-types/overview/${connectorType.serviceType}.png`}
                alt="Connector type logo"
              />
              <Typography
                variant="caption"
                sx={{
                  padding: "4px 12px",
                  borderRadius: "16px",
                  backgroundColor: isConnected ? "#66BB6A1F" : "#EEEEEE",
                  color: isConnected ? "#062F41" : "#062F41",
                }}
              >
                {isConnected ? t("Connected") : t("Connect")}
              </Typography>
            </Stack>
          ) : (
            <Box
              component="img"
              sx={{
                ...connectorBoxImageStyles,
                maxWidth: "156px",
                maxHeight: "67px",
              }}
              src={`/images/connector-types/${connectorType.serviceType}.png`}
              alt="Connector type logo"
            />
          )}
        </>
      )}
    </Box>
  );
};

export default NativeFivetranConnector;
