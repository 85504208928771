export class ConnectorTypeCategory {
  public "@id"?: string;

  constructor(
    _id?: string,
    public name?: string,
    public name_ar?: string,
    public description?: string,
    public description_ar?: string,
    public connectorTypes?: Array<string>,
    public enabledConnectorTypes?: Array<string>,
    public id?: string,
    public createdDate?: string,
    public updatedDate?: string,
    public connectFormDescription?: string,
    public connectFormDescription_ar?: string,
    public connectFormTitle?: string,
    public connectFormTitle_ar?: string,
    public enabled?: boolean,
    public orderIndex?: number
  ) {
    this["@id"] = _id;
  }
}

export enum ConnectorServiceTypeEnum {
  BANK_ACCOUNT = "bank_account",
  CSV_ACCOUNTING_SOFTWARE = "csv_accounting_software",
  CSV_MARKETING_CHANNELS = "csv_marketing_channels",
  CSV_PAYMENTS_SOFTWARE = "csv_payments_software",
  CSV_SALES_SOFTWARE = "csv_sales_software",
  DAFTRA = "daftra",
  FACEBOOK_ADS = "facebook_ads",
  FACEBOOK_ADS_CUSTOM = "facebook_ads_custom",
  GOOGLE_ADS = "google_ads",
  GOOGLE_ADS_CUSTOM = "google_ads_custom",
  APS = "amazon_payment_services",
  ASP = "amazon_selling_partner",
  HYPERPAY = "hyperpay",
  LEAN = "lean",
  MAGENTO = "magento",
  MONEYHASH = "moneyhash",
  MONEYHASH_HP = "moneyhash_hp",
  QUICKBOOKS = "quickbooks",
  QUICKBOOKS_CUSTOM = "quickbooks_custom",
  TIKTOK_ADS_CUSTOM = "tiktok_ads_custom",
  SNAPCHAT_ADS_CUSTOM = "snapchat_ads_custom",
  SHOPIFY = "shopify",
  SHOPIFY_CUSTOM = "shopify_custom",
  STRIPE = "stripe",
  STRIPE_CUSTOM = "stripe_custom",
  TAPPAYMENTS = "tappayments",
  TABBY = "tabby",
  CHECKOUT = "checkout",
  WAFEQ = "wafeq",
  WOOCOMMERCE = "woocommerce",
  WIO = "wio",
  XERO = "xero",
  XERO_CUSTOM = "xero_custom",
  ZID = "zid",
  ZOHO = "zoho",
  SALLA = "salla",
}

export enum ConnectorTypeEnum {
  CUSTOM = "custom",
  CUSTOM_FIVETRAN = "custom_fivetran",
  NATIVE_FIVETRAN = "native_fivetran",
}

export class ConnectorType {
  public "@id"?: string;

  constructor(
    _id?: string,
    public serviceType?: ConnectorServiceTypeEnum,
    public name?: string,
    public name_ar?: string,
    public description?: string,
    public description_ar?: string,
    public category?: string,
    public customConnectorAuthWindowTitle?: string,
    public customConnectorAuthWindowTitle_ar?: string,
    public customConnectorAuthWindowDescription?: string,
    public customConnectorAuthWindowDescription_ar?: string,
    public customConnectorFormDescription?: string,
    public customConnectorFormDescription_ar?: string,
    public orderIndex?: number,
    public enabled?: boolean,
    public id?: string,
    public country?: string,
    public createdDate?: string,
    public updatedDate?: string,
    public connectorType?: ConnectorTypeEnum
  ) {
    this["@id"] = _id;
  }
}

export type MoneyHashConnectorConfigurationData = {
  access_token: string;
};

export type LeanConnectorConfigurationData = {
  customer_id: string;
};
export type WioConfigurationData = {
  auth_detail: {
    oauth: string;
    codeChallenge: string;
  };
};

export type StripeConnectorConfigurationData = {
  account_link: string;
};

export type FacebookAdsConnectorConfigurationData =
  StripeConnectorConfigurationData;

export type SnapchatAdsConnectorConfigurationData =
  StripeConnectorConfigurationData;

export type LeanConnectorSource = {
  name: string;
  identifier: string;
  bank_type: string;
  logo: string;
  main_color: string;
  background_color: string;
};

export type CsvConnectorConfigurationData = {
  files: Array<string>;
};

export type NativeConnectorConfigurationData = {
  fivetran_storage: {
    data?: {
      id?: string;
      group_id?: string;
      service?: string;
      service_version?: number;
      schema?: string;
      connected_by?: string;
      created_at?: string;
      succeeded_at?: string | null;
      failed_at?: string | null;
      paused?: boolean;
      pause_after_trial?: boolean;
      sync_frequency?: number;
      schedule_type?: string;
      status?: {
        setup_state?: string;
        schema_status?: string;
        sync_state?: string;
        update_state?: string;
        is_historical_sync?: boolean;
        tasks?: Array<any>;
        warnings?: Array<any>;
      };
      setup_tests?: [
        {
          title?: string;
          status?: "FAILED" | "SKIPPED" | "PASSED";
          message?: string;
        }
      ];
    };
    card_token?: {
      token?: string;
      connector_id?: string;
    };
  };
};
export type ConnectorConfigurationDetail = {
  oauth: string;
  codeChallenge: string;
};
export class Connector {
  public "@id"?: string;

  constructor(
    _id?: string,
    public connectorType?: string,
    public machineName?: string,
    public index?: number,
    public configurationData?:
      | CsvConnectorConfigurationData
      | NativeConnectorConfigurationData
      | MoneyHashConnectorConfigurationData
      | StripeConnectorConfigurationData
      | LeanConnectorConfigurationData
      | WioConfigurationData,
    public configurationDetail?: ConnectorConfigurationDetail,
    public isConnected?: boolean,
    public reconnect_id?: string,
    public company?: string,
    public connectorTypeName?: string,
    public id?: string,
    public createdDate?: string,
    public updatedDate?: string
  ) {
    this["@id"] = _id;
  }
}

export type SallaConnector = {
  id: string;
};
