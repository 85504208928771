import { ErrorOutline } from "@mui/icons-material";
import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { Accept, FileError, FileRejection, useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { PrivateFile } from "../../types/PrivateFile";
import { uuidv4 } from "../../utils/uuid";
import SingleFile from "./SingleFile";
import { SingleFileUploadWithProgress } from "./SingleFileUploadWithProgress";
import UploadErrorFile from "./UploadErrorFile";
import { useIsMobile } from "../../hooks/useIsMobile";

interface UploadMultiFileProps {
  acceptedFileTypes?: Accept;
  disabled?: boolean;
  filesLimit?: number;
  fieldName: string;
  maxFileSize?: number;
  sxDropzone?: SxProps<Theme>;
  sxWrapper?: SxProps<Theme>;
  dropzoneContent?: ReactNode;
  isInitialFiles?: PrivateFile[];
  onUploadCallback?: () => any;
}

export interface UploadableFile {
  id: number;
  file: File | PrivateFile;
  errors: FileError[];
  url?: string;
}

const UploadMultiFile = ({
  acceptedFileTypes,
  disabled = false,
  fieldName,
  filesLimit = 40,
  maxFileSize = 26214400, // 25MB
  sxDropzone = [],
  sxWrapper = [],
  dropzoneContent,
  isInitialFiles = [],
  onUploadCallback = () => {},
}: UploadMultiFileProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [, meta, { setValue }] = useField(fieldName);
  const mappedInitialValue =
    meta.value &&
    !!meta.value.length &&
    meta.value.map((file: PrivateFile) => ({
      file,
      errors: [],
      id: uuidv4(),
    }));
  const [files, setFiles] = useState<UploadableFile[]>(
    mappedInitialValue || []
  );
  const [privateFiles, setPrivateFiles] = useState<PrivateFile[]>(
    meta.value || []
  );

  const { setFieldTouched } = useFormikContext();

  // When an element is dropped to dropzone we fire this event.
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const mappedAcc = acceptedFiles.map((file) => ({
        file,
        errors: [],
        id: uuidv4(),
      }));
      const mappedRej = rejectedFiles.map((r) => ({ ...r, id: uuidv4() }));
      setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
    },
    []
  );

  // When a file is uploaded we fire this event.
  const onUpload = (file: File, uploadedFile: PrivateFile) => {
    setFiles((curr) => {
      return curr.map((fw) => {
        if (fw.file === file) {
          return { ...fw, file: uploadedFile };
        }
        return fw;
      });
    });
    setPrivateFiles((prevState) => {
      return [...prevState, uploadedFile];
    });
    setFieldTouched(fieldName, true);
    onUploadCallback();
  };

  useEffect(() => {
    setValue(privateFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateFiles]);

  // When a file is removed we fire this event.
  const onRemove = (file: File | PrivateFile) => {
    setFiles((curr) => curr.filter((fw) => fw.file !== file));
    setPrivateFiles((prevState: PrivateFile[]) => {
      return prevState.filter((fw: PrivateFile) => fw !== file);
    });
  };
  const checkRemoveable = (file: PrivateFile) => {
    if (isInitialFiles && isInitialFiles.length > 0) {
      const objectExistsInArray = isInitialFiles.some(
        (object) => object["@id"] === file["@id"]
      );
      return !objectExistsInArray;
    }
    return true;
  };
  // When we have an error in upload we fire this event.
  const onError = useCallback((file: File, error: string) => {
    const fileError = {
      message: error,
      code: "api-error",
    };

    setFiles((curr) =>
      curr.map((fw) => {
        if (fw.file === file) {
          return { ...fw, errors: [...fw.errors, fileError] };
        }
        return fw;
      })
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: acceptedFileTypes,
      useFsAccessApi: false,
      disabled,
      maxFiles: filesLimit,
      maxSize: maxFileSize,
      multiple: true,
      onDrop,
    });

  return (
    <Box sx={[...(Array.isArray(sxWrapper) ? sxWrapper : [sxWrapper])]}>
      {files.length < filesLimit && (
        <Box
          {...getRootProps()}
          sx={[
            {
              background: "rgba(0, 0, 0, 0.04)",
              border: "1px dashed",
              borderColor: (theme) => theme.palette.grey[300],
              borderRadius: "6px",
              py: 2,
              px: 2.5,
              minHeight: { xs: "50px", sm: "80px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": { opacity: 0.72, cursor: "pointer" },
              ...(isDragActive && { opacity: 0.72 }),
              ...(isDragReject && {
                color: "error.main",
                borderColor: "error.light",
              }),
            },
            ...(Array.isArray(sxDropzone) ? sxDropzone : [sxDropzone]),
          ]}
        >
          <input {...getInputProps()} />

          {dropzoneContent ||
            (isMobile ? (
              <Typography variant="body2">
                <Box component="span" sx={{ color: "#04E2CB" }}>
                  Select File
                </Box>
              </Typography>
            ) : (
              <Typography variant="body2">
                <Trans i18nKey={"global.drag_and_drop"}>
                  Drag & drop your file here or{" "}
                  <Box component="span" sx={{ color: "#06D6E9" }}>
                    browse
                  </Box>
                </Trans>
              </Typography>
            ))}
        </Box>
      )}

      {!!files.length && (
        <Box
          sx={{
            mt: 1.5,
          }}
        >
          <Typography
            variant="h5"
            color="text"
            sx={{
              my: 1.5,
              textAlign: "center",
            }}
          >
            {t("global.uploaded_files")}
          </Typography>

          <Box>
            {files.map((fileWrapper: UploadableFile) => (
              <Box
                key={fileWrapper.id}
                sx={{ ":not(:last-child)": { mb: 0.75 } }}
              >
                <Box
                  sx={{
                    padding: "10px 12px",
                    backgroundColor: (theme) => theme.palette.grey[50],
                    borderRadius: "2px",
                  }}
                >
                  {!!fileWrapper.errors.length &&
                    fileWrapper.file instanceof File && (
                      <UploadErrorFile
                        onRemove={onRemove}
                        file={fileWrapper.file}
                      />
                    )}

                  {!fileWrapper.errors.length &&
                    fileWrapper.file instanceof File && (
                      <SingleFileUploadWithProgress
                        file={fileWrapper.file}
                        onRemove={onRemove}
                        onUpload={onUpload}
                        onError={onError}
                      />
                    )}

                  {!fileWrapper.errors.length &&
                    !(fileWrapper.file instanceof File) && (
                      <SingleFile
                        onRemove={onRemove}
                        removeable={checkRemoveable(fileWrapper.file)}
                        file={fileWrapper.file}
                      />
                    )}
                </Box>

                {!!fileWrapper.errors.length && (
                  <Box sx={{ my: 2 }}>
                    {fileWrapper.errors.map((error) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        key={error.code}
                      >
                        <ErrorOutline color="error" />
                        <Typography variant="subtitle2" color="error.main">
                          {error.message}
                        </Typography>
                      </Stack>
                    ))}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {meta.touched && meta.error && (
        <Stack direction="row" alignItems="center" spacing={1} sx={{ my: 2 }}>
          <ErrorOutline color="error" />
          <Typography variant="subtitle2" color="error.main">
            {meta.error}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default UploadMultiFile;
