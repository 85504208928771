//Country and CR number fields
import { Box, Button, LinearProgress } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useRef } from "react";
import { useGetCompanyFullInfo } from "../../queries/company";
import useAuth from "../../hooks/useAuth";
import { isLngAR } from "../../utils/i18n";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useIsMobile } from "../../hooks/useIsMobile";

export interface VerificationFormProps {
  activeStep?: any;
  handleBack?: any;
  changeCompanyDetail: (companyDetail: {}) => void;
}

const VerificationForm = ({
  activeStep,
  handleBack,
  changeCompanyDetail,
}: VerificationFormProps) => {
  const { company } = useAuth();
  const { t } = useTranslation(["translation", "countries"]);
  const formikRef = useRef(null);
  const { mutate: getCompanyFullInfo } = useGetCompanyFullInfo();
  const isMobile = useIsMobile();

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        crNumber: company?.registrationNumber,
      }}
      validationSchema={Yup.object().shape({
        crNumber: Yup.string()
          .required(t("error.company.cr_number.required"))
          .typeError(t("error.company.cr_number.invalid"))
          .matches(/^[0-9]{10}$/, t("error.company.cr_number.invalid")),
      })}
      onSubmit={(values, { setStatus, setErrors, setSubmitting }) => {
        const { crNumber } = values;
        getCompanyFullInfo(
          { cr: crNumber ?? "" },
          {
            onError: (error) => {
              setErrors({
                crNumber: t("error.company.cr_number.invalid"),
              });
              setSubmitting(false);
            },
            onSuccess: (data) => {
              if (data) {
                changeCompanyDetail(data);
                setSubmitting(false);
              }
            },
            onSettled: () => {
              setSubmitting(false);
            },
          }
        );
      }}
    >
      {({ isSubmitting, touched, errors, isValid, values }) => (
        <Form>
          {company?.countryCode === "SA" && (
            <>
              <Field
                id="field-cr-number"
                type="text"
                name="crNumber"
                variant="standard"
                component={TextField}
                label={t("company.field.enter_your_crNumber")}
                fullWidth
                disabled={isSubmitting}
              />
              {isSubmitting && (
                <Box sx={{ mb: 3, mt: 3 }}>
                  <LinearProgress />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 5,
                  gap: isMobile ? 2 : "auto",
                }}
              >
                <Button
                  color="inherit"
                  variant="outlined"
                  disabled={activeStep === 0 || isSubmitting}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t("global.back")}
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  type="submit"
                  variant="outlined"
                  endIcon={isLngAR() ? <ArrowBackIcon /> : <ArrowForwardIcon />}
                  id="continue-button"
                  sx={{
                    width: { xs: "100%", sm: "inherit" },
                  }}
                  disabled={isSubmitting || !isValid}
                >
                  {t("global.continue")}
                </Button>
              </Box>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default VerificationForm;
