import { Button, Stack, Grid, Box, Typography } from "@mui/material";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import FandBIcon from "../Icons/FandBIcon";
import EcommerceIcon from "../Icons/EcommerceIcon";
import RetailIcon from "../Icons/RetailIcon";
import HealthIcon from "../Icons/HealthIcon";
import BtoBIcon from "../Icons/BtoBIcon";
import { useRevenueSegmentInfoPost } from "../../queries/businessInfo";
import ViolationError from "../../utils/violationError";
import { useIsMobile } from "../../hooks/useIsMobile";

export type VerifyBusinessFormsType = {
  currentPage: number;
  handleChangePage: (new_page: number) => void;
};

export const RevenueSegmentOptions = [
  {
    id: 1,
    label: "pages.revenue_segment.restaurant",
    value: "restaurants",
    icon: <FandBIcon />,
  },
  {
    id: 2,
    label: "pages.revenue_segment.ecommerce",
    value: "ecommerce",
    icon: <EcommerceIcon />,
  },
  {
    id: 3,
    label: "pages.revenue_segment.physical_retail",
    value: "retail",
    icon: <RetailIcon />,
  },
  {
    id: 4,
    label: "pages.revenue_segment.clinic",
    value: "clinic",
    icon: <HealthIcon />,
  },
  {
    id: 5,
    label: "pages.revenue_segment.b2b",
    value: "b2b",
    icon: <BtoBIcon />,
  },
];

const STAND_ALONE_SEGMENTS = ["restaurants", "clinic"];

const ChooseRevenueSegments = ({
  handleChangePage,
  currentPage,
}: VerifyBusinessFormsType) => {
  const { t } = useTranslation(["translation", "countries"]);
  const { company, refetchCompany } = useAuth();

  const { mutate: verifyRevenueSegments } = useRevenueSegmentInfoPost();
  const [selected, setSelected] = useState<string[]>(
    company?.["revenueSegments"] ?? []
  );
  const [, setError] = useState<string | null>(null);
  const isMobile = useIsMobile();

  const handleSelection = (segment: string) => {
    setSelected((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(segment);

      if (STAND_ALONE_SEGMENTS.includes(segment)) {
        if (isAlreadySelected) {
          return prevSelected.filter((item) => item !== segment);
        }
        return [segment];
      }

      if (isAlreadySelected) {
        return prevSelected.filter((item) => item !== segment);
      } else {
        const filtered = prevSelected.filter(
          (item) => !STAND_ALONE_SEGMENTS.includes(item)
        );
        return [...filtered, segment];
      }
    });
  };

  const handleSubmit = () => {
    verifyRevenueSegments(
      {
        segments: selected,
      },
      {
        onError: (error) => {
          if (error instanceof ViolationError) {
            setError(`${error.defaultErrorMsg}`);
          } else {
            setError(`${error.message}`);
          }
        },
        onSuccess: (data) => {
          if (data.success) {
            refetchCompany();
            handleChangePage(currentPage + 1);
          } else {
            setError(t("error.unexpected"));
          }
        },
        onSettled: () => {
          handleChangePage(currentPage + 1);
        },
      }
    );
  };
  return (
    <>
      <Typography variant="h3" component="h1" sx={{ mb: 2 }}>
        {t("pages.choose_segments.heading")}
      </Typography>
      <Typography
        variant="body2"
        component="h1"
        sx={{
          mb: { xs: 2, sm: 2 },
          color: { xs: "text.secondary", sm: "inherit" },
        }}
      >
        {t("pages.choose_segments.title")}
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ mt: { xs: 2, sm: 4 }, mb: { xs: 3, sm: 6 } }}
      >
        {RevenueSegmentOptions.map((rev) => (
          <Grid item xs={isMobile ? 12 : 6} key={rev.id}>
            <Box
              onClick={() => handleSelection(rev.value)}
              sx={
                selected.includes(rev.value)
                  ? {
                      justifyContent: isMobile ? "center" : "flex-start",
                      height: "50px",
                      borderRadius: "8px",
                      textAlign: "left",
                      border: "1px solid #062F41",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      pl: isMobile ? "0" : "18px",
                    }
                  : {
                      justifyContent: isMobile ? "center" : "flex-start",
                      height: "50px",
                      borderRadius: "8px",
                      textAlign: "left",
                      border: "1px solid #062F41",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      pl: isMobile ? "0" : "18px",
                      opacity: "25%",
                    }
              }
            >
              <Box>{rev.icon}</Box>
              <Typography
                sx={{
                  fontSize: "100%",
                }}
              >
                {t(rev.label)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Button
          id="revenue-detail-button"
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          size="large"
          disabled={selected.length === 0}
          sx={{
            px: { xs: 7, sm: 4 },
          }}
        >
          {t("global.continue")}
        </Button>
      </Stack>
    </>
  );
};

export default ChooseRevenueSegments;
