export enum CONNECTOR_STEPS {
  BANK_ACCOUNT = "Bank account",
  SALES_SOFTWARE = "Sales software",
  ACCOUNTING_SOFTWARE = "Accounting software",
  BUSINESS_PREMISES = "Business Premises",
  COMPANY_DOCUMENTS = "Company Documents",
  MARKETING_CHANNELS = "Marketing channels",
  ADDITIONAL_COMPANY_DOCUMENTS = "Additional Company Documents",
}

export const steps = [
  CONNECTOR_STEPS.BANK_ACCOUNT,
  CONNECTOR_STEPS.SALES_SOFTWARE,
  CONNECTOR_STEPS.ACCOUNTING_SOFTWARE,
  CONNECTOR_STEPS.BUSINESS_PREMISES,
  CONNECTOR_STEPS.COMPANY_DOCUMENTS,
  CONNECTOR_STEPS.MARKETING_CHANNELS,
  CONNECTOR_STEPS.ADDITIONAL_COMPANY_DOCUMENTS,
];

export const stepsDetails = {
  [CONNECTOR_STEPS.BANK_ACCOUNT]: {
    id: 1,
    required: true,
    summary_title: "pages.steps_details.bank_account.title",
    summary_desc: "pages.steps_details.bank_account.summary",
    countries: ["SA", "AE"],
  },
  [CONNECTOR_STEPS.SALES_SOFTWARE]: {
    id: 2,
    required: true,
    summary_title: "pages.steps_details.sales_software.description",
    summary_desc: "pages.steps_details.sales_software.summary",
    countries: ["SA", "AE"],
  },
  [CONNECTOR_STEPS.ACCOUNTING_SOFTWARE]: {
    id: 3,
    required: true,
    summary_title: "pages.steps_details.accounting_software.description",
    summary_desc: "pages.steps_details.accounting_software.summary",
    countries: ["SA", "AE"],
  },
  [CONNECTOR_STEPS.BUSINESS_PREMISES]: {
    id: 4,
    required: true,
    summary_title: "pages.steps_details.business_premises.description",
    summary_desc: "pages.steps_details.business_premises.summary",
    countries: ["SA", "AE"],
  },
  [CONNECTOR_STEPS.COMPANY_DOCUMENTS]: {
    id: 5,
    required: true,
    summary_title: "pages.steps_details.company_documents.description",
    summary_desc: "pages.steps_details.company_documents.summary",
    countries: ["SA", "AE"],
  },
  [CONNECTOR_STEPS.MARKETING_CHANNELS]: {
    id: 6,
    required: false,
    summary_title: "pages.steps_details.marketing_channels.title",
    summary_desc: "pages.steps_details.marketing_channels.summary",
    countries: ["SA", "AE"],
  },
  [CONNECTOR_STEPS.ADDITIONAL_COMPANY_DOCUMENTS]: {
    id: 7,
    required: true,
    summary_title: "pages.steps_details.company_documents.title",
    summary_desc: "",
    countries: ["SA"],
  },
};

export function getStepsToShowFromSegments(
  segments: string[],
  countryCode: string
): CONNECTOR_STEPS[] {
  const finalSteps: CONNECTOR_STEPS[] = [CONNECTOR_STEPS.BANK_ACCOUNT];

  const multiSegments = segments.filter((s) =>
    ["ecommerce", "retail", "b2b"].includes(s)
  );
  const isMultiSegment = multiSegments.length > 1;
  if (isMultiSegment) {
    // Multi-segment logic:
    finalSteps.push(CONNECTOR_STEPS.SALES_SOFTWARE);
    finalSteps.push(CONNECTOR_STEPS.ACCOUNTING_SOFTWARE);
    finalSteps.push(CONNECTOR_STEPS.BUSINESS_PREMISES);
    finalSteps.push(CONNECTOR_STEPS.MARKETING_CHANNELS);
  } else {
    // Single-segment logic:
    if (segments.includes("ecommerce")) {
      finalSteps.push(CONNECTOR_STEPS.SALES_SOFTWARE);
      finalSteps.push(CONNECTOR_STEPS.MARKETING_CHANNELS);
    }
    if (segments.includes("clinic")) {
      finalSteps.push(CONNECTOR_STEPS.BUSINESS_PREMISES);
      stepsDetails[CONNECTOR_STEPS.SALES_SOFTWARE].required = false;
      finalSteps.push(CONNECTOR_STEPS.SALES_SOFTWARE);
      finalSteps.push(CONNECTOR_STEPS.MARKETING_CHANNELS);
    }
    if (segments.includes("b2b")) {
      finalSteps.push(CONNECTOR_STEPS.BUSINESS_PREMISES);
      finalSteps.push(CONNECTOR_STEPS.ACCOUNTING_SOFTWARE);
    }
    if (segments.includes("retail")) {
      finalSteps.push(CONNECTOR_STEPS.BUSINESS_PREMISES);
    }
    if (segments.includes("restaurants")) {
      finalSteps.push(CONNECTOR_STEPS.BUSINESS_PREMISES);
    }
  }

  if (countryCode === "SA") {
    finalSteps.push(CONNECTOR_STEPS.ADDITIONAL_COMPANY_DOCUMENTS);
  } else {
    finalSteps.push(CONNECTOR_STEPS.COMPANY_DOCUMENTS);
  }

  return finalSteps;
}
