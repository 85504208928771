import {
  Box,
  Container,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Markup } from "interweave";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsMutating } from "react-query";
import {
  AR_HTML_ALLOWED_TAGS_LIST,
  HTML_ALLOWED_TAGS_LIST,
} from "../../config/config";
import {
  useConnectorsQuery,
  useConnectorTypeCategoryQuery,
  useConnectorTypesFromCategoryQuery,
} from "../../queries/connector";
import {
  ConnectorServiceTypeEnum,
  ConnectorType,
  ConnectorTypeEnum,
} from "../../types/Connector";
import {
  BankAccountConnector,
  CsvConnector,
  CustomFivetranConnector,
  NativeFivetranConnector,
} from "../Connectors";
import { isLngAR } from "../../utils/i18n";
import CustomConnectorError from "../Connectors/CustomFivetranConnector/CustomConnectorError";
import { useIsMobile } from "../../hooks/useIsMobile";
import useAuth from "../../hooks/useAuth";
export const connectorBoxStyles = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const connectorBoxImageStyles = {
  display: "block",
  maxWidth: "67px",
  maxHeight: "67px",
};

type ConnectorsByCategoryV2Type = {
  connectorTypeCategoryId: string;
  refreshConnectors: () => void;
};

const ConnectorsByCategoryV2 = ({
  connectorTypeCategoryId,
  refreshConnectors,
}: ConnectorsByCategoryV2Type) => {
  const { company } = useAuth();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const {
    data: connectors,
    isLoading: connectorsLoading,
    error: connectorsError,
  } = useConnectorsQuery();
  const {
    data: connectorTypeCategory,
    isLoading: connectorTypeCategoryLoading,
    error: connectorTypeCategoryError,
  } = useConnectorTypeCategoryQuery(
    `connector_type_categories/${connectorTypeCategoryId!}`
  );
  const connectorTypesQuery = useConnectorTypesFromCategoryQuery(
    connectorTypeCategory?.enabledConnectorTypes || []
  );
  const connectorTypesLoading = connectorTypesQuery?.some(
    (connectorTypeQuery) => connectorTypeQuery.isLoading
  );
  const connectorTypesError = connectorTypesQuery?.some(
    (connectorTypeQuery) => connectorTypeQuery.isError
  );
  const [connectionError, setConnectionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const connectorTypes = [] as ConnectorType[];
  const csvConnectorTypes = [] as ConnectorType[];
  const bankAccountConnectorTypes = [] as ConnectorType[];
  // Check if specific mutation is taking place to show loading.
  const mutatingData = useIsMutating({
    predicate: (mutation) => {
      if (!mutation.options?.mutationKey) return false;
      return [
        "updateConnectorsConfiguration",
        "connectorsPost",
        "connectorsDelete",
        "privateFileUpload",
      ].includes(mutation.options.mutationKey as string);
    },
  });
  const [isCsvConnectorExpanded, setIsCsvConnectorExpanded] = useState(false);

  // Split the connectors to different types.
  if (!connectorTypesLoading && !connectorTypesError) {
    connectorTypesQuery?.forEach((connectorTypeQuery) => {
      if (connectorTypeQuery.data && connectorTypeQuery.data.enabled) {
        if (
          connectorTypeQuery.data.serviceType ===
          ConnectorServiceTypeEnum.BANK_ACCOUNT
        ) {
          bankAccountConnectorTypes.push(connectorTypeQuery.data);
        } else if (connectorTypeQuery.data.serviceType?.startsWith("csv_")) {
          csvConnectorTypes.push(connectorTypeQuery.data);
        } else {
          connectorTypes.push(connectorTypeQuery.data);
        }
      }
    });
  }

  useEffect(() => {
    if (!csvConnectorTypes || !connectors || !connectors["hydra:member"]) {
      return;
    }
    let isExpandedByDefault = false;
    csvConnectorTypes.forEach((connectorType) => {
      const connector = connectors["hydra:member"]
        .filter((connector) => connector.connectorType === connectorType["@id"])
        .shift();

      isExpandedByDefault =
        connector?.configurationData && "files" in connector.configurationData
          ? connector.configurationData.files.length > 0
          : false;
    });
    bankAccountConnectorTypes.forEach((connectorType) => {
      const connector = connectors["hydra:member"]
        .filter((connector) => connector.connectorType === connectorType["@id"])
        .shift();

      isExpandedByDefault =
        connector?.configurationData && "files" in connector.configurationData
          ? connector.configurationData.files.length > 0
          : false;
    });
    setIsCsvConnectorExpanded(isExpandedByDefault);
  }, [connectors, csvConnectorTypes.length, bankAccountConnectorTypes.length]);

  useEffect(() => {
    // Disable back button if needed or reset it.
    let timeout: NodeJS.Timeout | undefined;
    if (mutatingData > 0) {
      if (timeout) clearTimeout(timeout);
    } else {
      timeout = setTimeout(() => {}, 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [mutatingData]);

  const getCsvUploadIntro = (categoryName?: string) => {
    if (!categoryName) return null;

    if (categoryName.includes("Sales")) {
      return (
        <>
          {t("connectors.csv_connector.upload_spreadsheet")}
          <Box
            component="a"
            href="/Sales Data Template.xlsx"
            target="_blank"
            sx={{
              color: "primary.main",
              textDecoration: "underline",
            }}
          >
            {t("connectors.csv_connector.download_example")}
          </Box>
        </>
      );
    } else if (categoryName.includes("Accounting")) {
      return t("connectors.csv_connector.upload_management_accounts");
    } else if (categoryName.includes("Marketing")) {
      return t("connectors.csv_connector.upload_marketing_data");
    }

    return null;
  };

  return (
    <Container maxWidth="lg" disableGutters>
      <CustomConnectorError
        error={t("error.connectors.error.unexpected")}
        errorMessage={errorMessage}
        show={connectionError}
        handleShow={() => setConnectionError(false)}
      />

      <Stack direction={isMobile ? "column-reverse" : "row"} spacing={4}>
        <Box sx={{ flexGrow: 1 }}>
          {(connectorTypeCategoryLoading || connectorsLoading) && (
            <LinearProgress />
          )}

          {(connectorTypeCategoryError || connectorsError) && (
            <Typography variant="body1">
              {t("error.something_went_wrong.while_fetching")}
            </Typography>
          )}

          {connectorTypeCategory && connectors && (
            <>
              {isLngAR() ? (
                <>
                  <Typography variant="h4" sx={{ mb: 1.5 }}>
                    {connectorTypeCategory?.connectFormTitle_ar
                      ? connectorTypeCategory?.connectFormTitle_ar
                      : connectorTypeCategory?.connectFormTitle}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", mb: 3 }}
                  >
                    <Markup
                      content={
                        connectorTypeCategory?.connectFormDescription_ar
                          ? connectorTypeCategory?.connectFormDescription_ar
                          : connectorTypeCategory?.connectFormDescription
                      }
                      allowList={AR_HTML_ALLOWED_TAGS_LIST}
                      noWrap={true}
                    />
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h4" sx={{ mb: 1.5 }}>
                    {connectorTypeCategory?.connectFormTitle}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", mb: 3 }}
                  >
                    <Markup
                      content={connectorTypeCategory?.connectFormDescription}
                      allowList={HTML_ALLOWED_TAGS_LIST}
                      noWrap={true}
                    />
                  </Typography>
                </>
              )}

              {connectorTypesLoading && <LinearProgress />}

              {connectorTypesError && (
                <Typography variant="body1">
                  {t("error.something_went_wrong.while_fetching")}
                </Typography>
              )}

              <Grid container spacing={1.5}>
                {/* Native & Custom Connectors */}
                {connectorTypes.map((connectorType, index) => {
                  const connector = connectors["hydra:member"]
                    .filter(
                      (connector) =>
                        connector.connectorType === connectorType["@id"]
                    )
                    .shift();

                  if (
                    connectorType.country &&
                    connectorType.country !== company?.countryCode
                  ) {
                    return null;
                  }
                  const isConnected = connector && connector.isConnected;

                  return [
                    ConnectorTypeEnum.NATIVE_FIVETRAN,
                    ConnectorTypeEnum.CUSTOM_FIVETRAN,
                  ].includes(connectorType.connectorType!) ? (
                    <Grid item xs={6} md={6} key={index}>
                      <Box
                        key={connectorType.id}
                        sx={{
                          borderWidth: "1px",
                          height: "80px",
                          m: "1px",
                          borderStyle: "solid",
                          borderColor: (theme) =>
                            isConnected
                              ? "#00B272"
                              : theme.palette.action.disabledBackground,
                          backgroundColor: isConnected ? "#2E7D320A" : "",
                          borderRadius: "8px",
                          cursor: "pointer",
                          "&:hover, &:focus": {
                            m: 0,
                            borderWidth: "1px",
                            borderColor: (theme) =>
                              isConnected ? "" : theme.palette.primary.main,
                            backgroundColor: isConnected
                              ? "#2E7D320A"
                              : "#1976D20A",
                          },
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: { sx: 5 },
                        }}
                      >
                        {connectorType.connectorType ===
                          ConnectorTypeEnum.NATIVE_FIVETRAN && (
                          <NativeFivetranConnector
                            connector={connector}
                            connectorType={connectorType}
                            setConnectionError={setConnectionError}
                          />
                        )}

                        {connectorType.connectorType ===
                          ConnectorTypeEnum.CUSTOM_FIVETRAN && (
                          <CustomFivetranConnector
                            connectorType={connectorType}
                            setConnectionError={setConnectionError}
                            setErrorMessage={setErrorMessage}
                          />
                        )}
                      </Box>
                    </Grid>
                  ) : null;
                })}
                {/* csv bank */}
                <Grid item xs={12} key={"csv-bank-connector"}>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    {bankAccountConnectorTypes.map((connectorType, index) => {
                      const connector = connectors["hydra:member"]
                        .filter(
                          (connector) =>
                            connector.connectorType === connectorType["@id"]
                        )
                        .shift();

                      return (
                        <Box
                          key={connectorType.id}
                          sx={{
                            mb:
                              bankAccountConnectorTypes.length - 1 === index
                                ? 0
                                : 4,
                          }}
                        >
                          <BankAccountConnector
                            connector={connector}
                            connectorType={connectorType["@id"]!}
                            isExpanded={true}
                            refreshConnectors={refreshConnectors}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!connectorTypeCategory?.name?.includes("Bank") && (
                  <Box
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Stack direction={"row"} alignItems="center">
                      <Box
                        sx={{
                          borderBottom: "1px solid",
                          borderColor: (theme) => theme.palette.divider,
                          width: "100%",
                          marginBottom: 1,
                        }}
                      />

                      <Typography variant="body2" sx={{ mb: 1, p: 2 }}>
                        {t("connectors.csv_connector.or")}
                      </Typography>

                      <Box
                        sx={{
                          borderBottom: "1px solid",
                          borderColor: (theme) => theme.palette.divider,
                          width: "100%",
                          marginBottom: 1,
                        }}
                      />
                    </Stack>

                    <Typography variant="body2" sx={{ marginBottom: 3 }}>
                      {getCsvUploadIntro(connectorTypeCategory?.name)}
                    </Typography>
                  </Box>
                )}

                {/* Upload Files */}
                {!connectorTypesLoading && !!csvConnectorTypes.length && (
                  <Grid item xs={6} key={"csv-upload"}>
                    <Box
                      flexDirection="row"
                      onClick={() =>
                        setIsCsvConnectorExpanded(
                          (isCsvConnectorExpanded) => !isCsvConnectorExpanded
                        )
                      }
                      sx={{
                        ...connectorBoxStyles,
                        borderWidth: "1px",
                        m: "1px",
                        height: "80px",
                        borderStyle: "solid",
                        borderColor: (theme) =>
                          theme.palette.action.disabledBackground,
                        borderRadius: "8px",
                        cursor: "pointer",
                        "&:hover, &:focus": {
                          borderWidth: "1px",
                          borderColor: (theme) => theme.palette.primary.main,
                          backgroundColor: "#1976D20A",
                        },
                      }}
                    >
                      <Box
                        component="img"
                        src={`/images/connector-types/file-upload.png`}
                        alt="Connector type logo"
                        height={"45px"}
                      />
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mt: 1.25,
                          ml: "20px",
                          fontSize: { xs: "80%", sm: "100%" },
                        }}
                      >
                        {t("connectors.csv_connector.upload_files")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {/* Drag & drop your file here or browse */}
                {!!csvConnectorTypes.length && (
                  <Grid item xs={12} key={"csv-connector"}>
                    <Box
                      sx={{
                        width: "100%",
                        mt:
                          !!connectorTypes.length ||
                          !!bankAccountConnectorTypes.length
                            ? 2
                            : 0,
                      }}
                    >
                      {csvConnectorTypes.map((connectorType, index) => {
                        const connector = connectors["hydra:member"]
                          .filter(
                            (connector) =>
                              connector.connectorType === connectorType["@id"]
                          )
                          .shift();

                        return (
                          <Box
                            key={connectorType.id}
                            sx={{
                              mb:
                                csvConnectorTypes.length - 1 === index ? 0 : 4,
                            }}
                          >
                            <CsvConnector
                              connector={connector}
                              connectorType={connectorType["@id"]!}
                              isExpanded={isCsvConnectorExpanded}
                              isSalesCategory={connectorType.name?.startsWith(
                                "Sales"
                              )}
                              refreshConnectors={refreshConnectors}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Box>
      </Stack>
    </Container>
  );
};

export default ConnectorsByCategoryV2;
