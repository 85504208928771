import { useMutation, UseMutationResult } from "react-query";
import ViolationError from "../utils/violationError";
import { fetch } from "../utils/dataAccess";

interface IRequestCodeResponse {
  data: {};
  success: boolean;
}

interface IVerifyBusinessInfoOptionSegmentPostProps {
  segments: string[];
}

interface IVerifyBusinessInfoFundPurposePostProps {
  purposes: string[];
}

interface IVerifyBusinessInfoDesiredAmountPostProps {
  financingAmount: string;
}

interface IVerifyBusinessInfoAverageMonthlyRevenueProps {
  userRevenue: number;
  monthlyRevenue: number;
}

interface IVerifyBusinessInfoGeneratingProfitProps {
  generatingProfit: string;
}

interface IVerifyBusinessInfoHasTradeLicenseProps {
  hasTradeLicense: string;
}

interface IVerifyBusinessInfoHasActiveBankAccountProps {
  hasActiveBankAccount: string;
}

interface IVerifyBusinessInfoHasBeenOperationalProps {
  hasBeenOperational: string;
}

interface IVerifyBusinessInfoHasActiveLoansProps {
  hasActiveLoans: string;
}

interface IVerifyBusinessInfoLoanRepaymentMonthlyInstallmentProps {
  loanRepaymentMonthlyInstallment: string;
}

export const verifyRevenueSegmentInfo = async ({
  segments,
}: IVerifyBusinessInfoOptionSegmentPostProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      segments: segments,
    },
  });

  return data;
};

export const verifyFundPurposeInfo = async ({
  purposes,
}: IVerifyBusinessInfoFundPurposePostProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      purposes: purposes,
    },
  });

  return data;
};

export const verifyDesiredAmountInfo = async ({
  financingAmount,
}: IVerifyBusinessInfoDesiredAmountPostProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      financing_amount: financingAmount,
    },
  });

  return data;
};

export const verifyAverageMonthlyRevenueInfo = async ({
  userRevenue,
  monthlyRevenue,
}: IVerifyBusinessInfoAverageMonthlyRevenueProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      user_revenue: userRevenue,
      monthly_revenue: monthlyRevenue,
    },
  });

  return data;
};

export const verifyGeneratingProfitInfo = async ({
  generatingProfit,
}: IVerifyBusinessInfoGeneratingProfitProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      generating_profit: generatingProfit,
    },
  });

  return data;
};

export const verifyHasTradeLicenseInfo = async ({
  hasTradeLicense,
}: IVerifyBusinessInfoHasTradeLicenseProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      has_trade_license: hasTradeLicense,
    },
  });

  return data;
};

export const verifyHasActiveBankAccountInfo = async ({
  hasActiveBankAccount,
}: IVerifyBusinessInfoHasActiveBankAccountProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      has_active_bank_account: hasActiveBankAccount,
    },
  });

  return data;
};

export const verifyHasBeenOperationalInfo = async ({
  hasBeenOperational,
}: IVerifyBusinessInfoHasBeenOperationalProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      has_been_operational: hasBeenOperational,
    },
  });

  return data;
};

export const verifyHasActiveLoansInfo = async ({
  hasActiveLoans,
}: IVerifyBusinessInfoHasActiveLoansProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      has_active_loans: hasActiveLoans,
    },
  });

  return data;
};

export const verifyLoanRepaymentMonthlyInstallmentInfo = async ({
  loanRepaymentMonthlyInstallment,
}: IVerifyBusinessInfoLoanRepaymentMonthlyInstallmentProps) => {
  const { data } = await fetch({
    url: "/users/verify-business-info",
    method: "POST",
    data: {
      loan_repayment_monthly_installment: loanRepaymentMonthlyInstallment,
    },
  });

  return data;
};

export const useRevenueSegmentInfoPost = (): UseMutationResult<
  IRequestCodeResponse,
  Error | ViolationError,
  IVerifyBusinessInfoOptionSegmentPostProps
> => {
  return useMutation(verifyRevenueSegmentInfo);
};

export const useUpdateFundPurposePost = (): UseMutationResult<
  IRequestCodeResponse,
  Error | ViolationError,
  IVerifyBusinessInfoFundPurposePostProps
> => {
  return useMutation(verifyFundPurposeInfo);
};

export const useUpdateDesiredAmountPost = (): UseMutationResult<
  IRequestCodeResponse,
  Error | ViolationError,
  IVerifyBusinessInfoDesiredAmountPostProps
> => {
  return useMutation(verifyDesiredAmountInfo);
};

export const useUpdateAverageMonthlyRevenuePost = (): UseMutationResult<
  IRequestCodeResponse,
  Error | ViolationError,
  IVerifyBusinessInfoAverageMonthlyRevenueProps
> => {
  return useMutation(verifyAverageMonthlyRevenueInfo);
};

export const useUpdateGeneratingProfitPost = (): UseMutationResult<
  IRequestCodeResponse,
  Error | ViolationError,
  IVerifyBusinessInfoGeneratingProfitProps
> => {
  return useMutation(verifyGeneratingProfitInfo);
};

export const useUpdateHasTradeLicensePost = (): UseMutationResult<
  IRequestCodeResponse,
  Error | ViolationError,
  IVerifyBusinessInfoHasTradeLicenseProps
> => {
  return useMutation(verifyHasTradeLicenseInfo);
};

export const useUpdateHasActiveBankAccountPost = (): UseMutationResult<
  IRequestCodeResponse,
  Error | ViolationError,
  IVerifyBusinessInfoHasActiveBankAccountProps
> => {
  return useMutation(verifyHasActiveBankAccountInfo);
};

export const useUpdateHasBeenOperationalPost = (): UseMutationResult<
  IRequestCodeResponse,
  Error | ViolationError,
  IVerifyBusinessInfoHasBeenOperationalProps
> => {
  return useMutation(verifyHasBeenOperationalInfo);
};

export const useUpdateHasActiveLoansPost = (): UseMutationResult<
  IRequestCodeResponse,
  Error | ViolationError,
  IVerifyBusinessInfoHasActiveLoansProps
> => {
  return useMutation(verifyHasActiveLoansInfo);
};

export const useUpdateLoanRepaymentMonthlyInstallmentPost =
  (): UseMutationResult<
    IRequestCodeResponse,
    Error | ViolationError,
    IVerifyBusinessInfoLoanRepaymentMonthlyInstallmentProps
  > => {
    return useMutation(verifyLoanRepaymentMonthlyInstallmentInfo);
  };
