import useSettings from "../hooks/useSettings";

export const useTiktokAdsOAuth = () => {
  const { integrationConfig } = useSettings();

  const initiateTiktokAdsOAuth = () => {
    const redirect_uri = `${window.origin}/connector-redirect-uri/custom`;
    const queryParams = new URLSearchParams({
      app_id: integrationConfig.tiktokAdsClientId,
      redirect_uri: redirect_uri,
      state: localStorage.getItem("connectorRedirectUriConnectorId") ?? "",
    });
    window.location.href = `https://business-api.tiktok.com/portal/auth?${queryParams.toString()}`;
  };

  return { initiateTiktokAdsOAuth };
};
