import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import Page from "../Page";
import { Container } from "@mui/material";
import OnboardingStep, { OnboardingStepsEnum } from "../OnboardingStep";
import { PATH_ONBOARDING } from "../Routes/paths";

const OnboardingPages = () => {
  const { t } = useTranslation();
  const location = useLocation();

  let onboardingStep: OnboardingStepsEnum;
  if (
    location.pathname === PATH_ONBOARDING.underReview ||
    location.pathname === PATH_ONBOARDING.applicationOverview ||
    location.pathname === PATH_ONBOARDING.notEligible
  ) {
    onboardingStep = OnboardingStepsEnum.REVIEW_SUBMIT;
  } else if (
    location.pathname === PATH_ONBOARDING.verification ||
    location.pathname === PATH_ONBOARDING.additionalDocumentsKSA
  ) {
    onboardingStep = OnboardingStepsEnum.VERIFY_BUSINESS;
  } else if (
    location.pathname === PATH_ONBOARDING.connectSystems &&
    localStorage.getItem("VerifyBusinessStep")
  ) {
    onboardingStep = OnboardingStepsEnum.VERIFY_BUSINESS;
  } else {
    onboardingStep = OnboardingStepsEnum.CONNECT_DATA;
  }

  const getPageTitle = (step: OnboardingStepsEnum) => {
    switch (step) {
      case OnboardingStepsEnum.VERIFY_BUSINESS:
        return t("stepper.steps.verify_business");
      case OnboardingStepsEnum.REVIEW_SUBMIT:
        return t("stepper.steps.review_submit");
      case OnboardingStepsEnum.CONNECT_DATA:
      default:
        return t("stepper.steps.connect_data");
    }
  };

  return (
    <Page title={getPageTitle(onboardingStep)}>
      <Container sx={{ my: 5 }}>
        <OnboardingStep step={onboardingStep} />
        <Outlet />
      </Container>
    </Page>
  );
};

export default OnboardingPages;
