import { CircularProgress, Typography, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useConnectorsPost } from "../../../queries/connector";
import {
  ConnectorType,
  StripeConnectorConfigurationData,
} from "../../../types/Connector";
import { PATH_ONBOARDING, PATH_SETTINGS } from "../../Routes/paths";
import {
  connectorBoxImageStyles,
  connectorBoxStyles,
} from "../../ConnectorsByCategoryV2";
import { useTranslation } from "react-i18next";
import { initiateGoogleOAuth } from "../../../utils/googleAds";
import { useSnapchatOAuth } from "../../../utils/useSnapchatOAuth";
import { useQuickbooksOAuth } from "../../../utils/useQuickbooksOAuth";
import { useTiktokAdsOAuth } from "../../../utils/useTiktokAdsOAuth";
import { useXeroOAuth } from "../../../utils/useXeroOAuth";

type CustomFivetranConnectorProps = {
  connectorType: ConnectorType;
  setConnectionError: Dispatch<SetStateAction<boolean>>;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  overview?: Boolean;
  isConnected?: Boolean;
};

const CustomFivetranConnector = ({
  connectorType,
  setConnectionError,
  setErrorMessage,
  overview,
  isConnected,
}: CustomFivetranConnectorProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { company } = useAuth();
  const { initiateSnapchatOAuth } = useSnapchatOAuth();
  const { initiateQuickbooksOAuth } = useQuickbooksOAuth();
  const { initiateTiktokAdsOAuth } = useTiktokAdsOAuth();
  const { initiateXeroOAuth } = useXeroOAuth();
  const [, setConnectorId] = useLocalStorage<string | null>(
    "connectorRedirectUriConnectorId",
    null
  );

  const { mutateAsync: createConnectorMutation, isLoading: isCreating } =
    useConnectorsPost();

  const isStripeConnectorConfigurationData = (
    obj: Object
  ): obj is StripeConnectorConfigurationData => {
    return obj.hasOwnProperty("account_link");
  };

  const createConnector = async () => {
    await createConnectorMutation(
      { connectorType: connectorType["@id"]! },
      {
        onError: (error) => {
          setErrorMessage(error?.message || "");
          setConnectionError(true);
        },
        onSuccess: (data) => {
          setConnectorId(data.id!);

          // If we have to redirect user, redirect from here directly.
          // Previously this was done in files like
          // components/Connectors/CustomFivetranConnector/Stripe/Form.tsx.
          if (
            data &&
            data.configurationData &&
            isStripeConnectorConfigurationData(data.configurationData)
          ) {
            window.location.href = data.configurationData.account_link;
            return;
          }

          // Redirect to Google directly from here.
          if (data && data.machineName?.includes("google_ads_custom")) {
            initiateGoogleOAuth();
            return;
          }

          // Redirect to Snapchat directly from here.
          if (data && data.machineName?.includes("snapchat_ads_custom")) {
            initiateSnapchatOAuth();
            return;
          }

          // Redirect to Quickbooks directly from here.
          if (data && data.machineName?.includes("quickbooks_custom")) {
            initiateQuickbooksOAuth();
            return;
          }

          // Redirect to TiktokAds directly from here.
          if (data && data.machineName?.includes("tiktok_ads_custom")) {
            initiateTiktokAdsOAuth();
            return;
          }

          // Redirect to Xero directly from here.
          if (data && data.machineName?.includes("xero_custom")) {
            initiateXeroOAuth();
            return;
          }

          navigate(
            `${
              company?.kycKybStatus === "verified"
                ? PATH_SETTINGS.dataSourcesCustomFivetranConnector
                : PATH_ONBOARDING.customFivetranConnector
            }/${connectorType?.serviceType?.replace("_", "-")}`
          );
        },
      }
    );
  };

  return (
    <Box
      sx={connectorBoxStyles}
      onClick={createConnector}
      flexDirection="column"
    >
      {isCreating ? (
        <CircularProgress
          size="2rem"
          sx={{ color: (theme) => theme.palette.primary.main }}
        />
      ) : (
        <>
          {overview ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "80%" }}
            >
              <Box
                component="img"
                sx={{
                  ...connectorBoxImageStyles,
                  maxWidth: "156px",
                  maxHeight: "67px",
                }}
                src={`/images/connector-types/overview/${connectorType.serviceType}.png`}
                alt="Connector type logo"
              />
              <Typography
                variant="caption"
                sx={{
                  padding: "4px 12px",
                  borderRadius: "16px",
                  backgroundColor: isConnected ? "#66BB6A1F" : "#EEEEEE",
                  color: isConnected ? "#062F41" : "#062F41",
                }}
              >
                {isConnected ? t("Connected") : t("Connect")}
              </Typography>
            </Stack>
          ) : (
            <>
              <Box
                component="img"
                sx={{
                  ...connectorBoxImageStyles,
                  maxWidth: "156px",
                  maxHeight: "67px",
                }}
                src={`/images/connector-types/${connectorType.serviceType}.png`}
                alt="Connector type logo"
              />
              {connectorType.serviceType === "lean" ? (
                <Typography variant="subtitle2" sx={{ mt: 1.25 }}>
                  {t("connectors.custom_fivetran_connector.bank_statements")}
                </Typography>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default CustomFivetranConnector;
