import {
  Typography,
  Stack,
  Box,
  Button,
  Link,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Container } from "@mui/material";
import { useIsMobile } from "../../hooks/useIsMobile";
import { SUPPORT_MAIL, PRODUCT_LINK } from "../../config/config";
import { useNavigate } from "react-router-dom";
import { PATH_ONBOARDING } from "../Routes/paths";

export type NotEligibleForFundingExtendedType = {
  currentPage?: number;
  handleChangePage?: (new_page: number) => void;
  summary?: boolean;
};

const NotEligibleForFunding = ({
  handleChangePage,
  currentPage,
  summary,
}: NotEligibleForFundingExtendedType) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  return (
    <Container
      maxWidth="md"
      sx={{ pt: "53px", px: { xs: 2, sm: "inherit" } }}
      disableGutters
    >
      <Card sx={{ width: { sm: "1000px" } }}>
        <CardContent sx={{ p: { xs: 3, sm: 5 } }}>
          {isMobile && (
            <Box
              height="150px"
              position="relative"
              sx={{
                display: { lg: "block" },
                backgroundImage: `url(/images/rectangles/Rectangle_8.jpg)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                mb: 2,
              }}
            ></Box>
          )}
          <Stack direction="row" spacing={8}>
            <Box sx={{ flex: "1 0 0%", alignContent: "center" }}>
              <Typography variant="h3" component="h1" sx={{ mb: 1.5 }}>
                {t("pages.not_eligible_for_funding.heading")}
              </Typography>
              <Typography
                variant="body2"
                sx={{ mb: 3, color: "text.secondary" }}
              >
                {t("pages.not_eligible_for_funding.not_able_to_fund")}
              </Typography>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{ mb: 3, color: "text.secondary" }}
                  >
                    {t(
                      "pages.not_eligible_for_funding.funding_requirements_text.start"
                    )}
                    <Link href={PRODUCT_LINK} target="_blank">
                      {t(
                        "pages.not_eligible_for_funding.funding_requirements_text.our_requirements"
                      )}
                    </Link>
                    {t(
                      "pages.not_eligible_for_funding.funding_requirements_text.end"
                    )}
                  </Typography>
                }
              />
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{ mb: 3, color: "text.secondary" }}
                  >
                    {t("pages.not_eligible_for_funding.contact_us_text.start")}
                    <Link href={`mailto:${SUPPORT_MAIL}`} target="_blank">
                      {t(
                        "pages.not_eligible_for_funding.contact_us_text.contact_us"
                      )}
                    </Link>
                    {t("pages.not_eligible_for_funding.contact_us_text.end")}
                  </Typography>
                }
              />
              <Box>
                <Button
                  id="back-button"
                  variant="outlined"
                  color="primary"
                  size="large"
                  sx={{
                    width: { xs: "100%", sm: "inherit" },
                  }}
                  onClick={() => {
                    if (summary) {
                      navigate(PATH_ONBOARDING.applicationOverview);
                    } else if (handleChangePage && currentPage) {
                      handleChangePage(currentPage - 1);
                    } else {
                      return null;
                    }
                  }}
                >
                  {isMobile ? t("global.back_to_login") : t("global.back")}
                </Button>
              </Box>
            </Box>
            <Box
              width="380px"
              height="362px"
              position="relative"
              sx={{
                display: { xs: "none", lg: "block" },
                backgroundImage: `url(/images/rectangles/Rectangle_7.jpg)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                p: 4,
              }}
            ></Box>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

export default NotEligibleForFunding;
