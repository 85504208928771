import {
  Box,
  Button,
  Container,
  Checkbox,
  Link,
  Stack,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import {
  CONNECTOR_STEPS,
  steps,
  stepsDetails,
  getStepsToShowFromSegments,
} from "./ApplicationSteps";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useAuth from "../../hooks/useAuth";
import {
  useConnectorsQuery,
  useConnectorTypeCategoriesQuery,
} from "../../queries/connector";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH_ONBOARDING } from "../Routes/paths";
import { useEffect, useState } from "react";
import { useConnectSystemsDate } from "../../queries/company";
import { useKycKybStatus } from "../../queries/kycKyb";
import useSettings from "../../hooks/useSettings";
import * as Sentry from "@sentry/react";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Dot } from "../OnboardingConnectSystems";
import { CompanyDocumentsFormSection } from "../OnboardingCompanyDetail/CompanyDocuments";
import { BusinessPremisesFormSection } from "../OnboardingCompanyDetail/BusinessPremises";
import { AdditionalDocumentsKSAForm } from "../OnboardingVerificationCountry/AdditionalDocumentsKSA";
import ConnectorsByCategory from "./ConnectorsByCategory";
import { VerifyBusinessInfoFormQuestionnaire } from "../VerifyBusinessInfo/VerifyBusinessInfoForm";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { RevenueSegmentOptions } from "../VerifyBusinessInfo/ChooseRevenueSegmentsForm";
import { FundPurposes } from "../VerifyBusinessInfo/ChooseFundPurposeForm";

const ApplicationOverview = () => {
  const { t } = useTranslation(["translation"]);
  const { company, setCompany } = useAuth();
  const { data: connectors } = useConnectorsQuery();
  const { data: connectorTypeCategories } = useConnectorTypeCategoriesQuery();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const connectSystemsDateMutation = useConnectSystemsDate();
  const updatekycKybStatus = useKycKybStatus();
  const { integrationConfig } = useSettings();
  const isMobile = useIsMobile();
  const [completedSteps, setCompletedSteps] = useState<CONNECTOR_STEPS[]>([]);
  const [todoSteps, setTodoSteps] = useState<CONNECTOR_STEPS[]>([]);

  localStorage.setItem("onApplicationOverviewScreen", "true");

  type OpenStepType = CONNECTOR_STEPS | "BUSINESS_DETAILS" | null;
  const [openStep, setOpenStep] = useState<OpenStepType>(null);
  const { minimumEligibleRevenue } = useSettings();

  if (company?.systemsConnectedDate && !isChecked) {
    setIsChecked(true);
  }

  const handleBack = () => {
    if (company?.countryCode === "SA") {
      return navigate(PATH_ONBOARDING.additionalDocumentsKSA);
    } else {
      return navigate(PATH_ONBOARDING.connectSystems);
    }
  };

  useEffect(() => {
    const stepsToShow: CONNECTOR_STEPS[] = getStepsToShowFromSegments(
      company?.revenueSegments ?? [],
      company?.countryCode ?? ""
    );
    let completedSteps: CONNECTOR_STEPS[] = [];
    let todoSteps: CONNECTOR_STEPS[] = stepsToShow;

    if (connectors && connectorTypeCategories) {
      steps.map((step) => {
        const connectorTypeCategory = connectorTypeCategories[
          "hydra:member"
        ].find((category) => category.name === step);
        if (
          connectors["hydra:member"].some(
            (connector) =>
              connector.isConnected &&
              connectorTypeCategory?.enabledConnectorTypes?.includes(
                connector.connectorType ?? ""
              )
          )
        ) {
          if (stepsToShow.includes(step)) {
            completedSteps.push(step);
            todoSteps = todoSteps.filter((todoStep) => todoStep !== step);
          }
        }
      });
    }

    if (
      stepsToShow.includes(CONNECTOR_STEPS.COMPANY_DOCUMENTS) &&
      (company?.kycKybDocuments?.companyDocuments ?? []).length
    ) {
      completedSteps.push(CONNECTOR_STEPS.COMPANY_DOCUMENTS);
      todoSteps = todoSteps.filter(
        (step) => step !== CONNECTOR_STEPS.COMPANY_DOCUMENTS
      );
    }

    if (
      stepsToShow.includes(CONNECTOR_STEPS.BUSINESS_PREMISES) &&
      (company?.kycKybDocuments?.businessPremises ?? []).length
    ) {
      completedSteps.push(CONNECTOR_STEPS.BUSINESS_PREMISES);
      todoSteps = todoSteps.filter(
        (step) => step !== CONNECTOR_STEPS.BUSINESS_PREMISES
      );
    }

    if (
      stepsToShow.includes(CONNECTOR_STEPS.ADDITIONAL_COMPANY_DOCUMENTS) &&
      (company?.kycKybDocuments?.companyConsent ?? []).length > 0 &&
      (company?.kycKybDocuments?.shareholderConsent ?? []).length > 0
    ) {
      completedSteps.push(CONNECTOR_STEPS.ADDITIONAL_COMPANY_DOCUMENTS);
      todoSteps = todoSteps.filter(
        (step) => step !== CONNECTOR_STEPS.ADDITIONAL_COMPANY_DOCUMENTS
      );
    }

    todoSteps.sort((a, b) => {
      return stepsDetails[a].id - stepsDetails[b].id;
    });

    completedSteps.sort((a, b) => {
      return stepsDetails[a].id - stepsDetails[b].id;
    });

    setCompletedSteps(completedSteps);
    setTodoSteps(todoSteps);
  }, [company, connectorTypeCategories, connectors]);

  const allRequiredCompleted = todoSteps.every((step) => {
    if (stepsDetails[step].required) {
      return completedSteps.includes(step);
    }
    return true;
  });

  const revenueSegmentsText: string[] = [];
  RevenueSegmentOptions.map((rev) => {
    if (company?.revenueSegments?.includes(rev.value)) {
      revenueSegmentsText.push(t(rev.label));
    }
  });

  const fundPurposesText: string[] = [];
  FundPurposes.map((fund) => {
    if (company?.fundingPurposes?.includes(fund.value)) {
      fundPurposesText.push(t(fund.label));
    }
  });

  type CompanyDetailsBoxType = {
    label: any;
    value: any;
  };

  const CompanyDetailsBox = ({ label, value }: CompanyDetailsBoxType) => {
    return (
      <Box sx={{ flexDirection: "row", width: "30%" }}>
        <Typography variant="h5" sx={{ mb: 1.5 }}>
          {label}
        </Typography>
        <Typography sx={{ color: "#00000099" }}>{value}</Typography>
      </Box>
    );
  };

  return (
    <Container maxWidth="lg" disableGutters>
      <Card sx={{ my: { sm: 5 } }}>
        <CardContent sx={{ p: { xs: 3, sm: 5 } }}>
          <Stepper sx={{ display: "grid", mb: "30px" }}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <InfoOutlinedIcon color="info" />
              <Typography
                component="h1"
                variant="h5"
                sx={{ color: "text.primary" }}
              >
                {t("pages.application_overview.heading")}
              </Typography>
            </Stack>
            <Dot active={true} width={`${100}%`} />
          </Stepper>
          <Stack direction={"column"} spacing={4}>
            <Box>
              <Typography variant="h4" sx={{ mb: 1.5 }}>
                {t("pages.application_overview.heading")}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {t("pages.application_overview.subheading")}
              </Typography>
            </Box>

            <Box
              sx={{
                borderRadius: "12px",
                backgroundColor: "#FAFAFA",
                px: "32px",
                py: "26px",
              }}
            >
              <Stack
                spacing={"32px"}
                direction="row"
                sx={{ marginBottom: "2em" }}
              >
                <CompanyDetailsBox
                  label={t("pages.application_overview.overview.sector")}
                  value={revenueSegmentsText.join(", ")}
                />
                <CompanyDetailsBox
                  label={t("pages.application_overview.overview.company_name")}
                  value={company?.name}
                />
              </Stack>
              <Stack spacing={"32px"} direction="row">
                <CompanyDetailsBox
                  label={t("pages.application_overview.overview.purpose")}
                  value={fundPurposesText.join(", ")}
                />
                <CompanyDetailsBox
                  label={t(
                    "pages.application_overview.overview.requested_financing"
                  )}
                  value={`${company?.currency} ${
                    company?.companyDetails?.financingAmount || ""
                  }`}
                />
                <CompanyDetailsBox
                  label={t("pages.application_overview.overview.revenue")}
                  value={`${company?.currency} ${
                    company?.userRevenue?.toLocaleString() ?? ""
                  }`}
                />
              </Stack>
            </Box>

            {/* To-Do Section */}
            {todoSteps.length ? (
              <>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <InfoOutlinedIcon color="info" />
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{ color: "text.primary" }}
                  >
                    {t("pages.onboarding_overview.todo")}
                  </Typography>
                </Stack>
                <Stack direction={"column"} spacing={1.5}>
                  {todoSteps.map((step, index) => (
                    <SummaryDetailBox
                      key={index}
                      icon_type="info"
                      label={step}
                      stepIndex={steps.indexOf(step)}
                      isSkipped={true}
                      openStep={openStep}
                      setOpenStep={setOpenStep}
                    />
                  ))}
                </Stack>
              </>
            ) : (
              ""
            )}

            {/* Done Section */}
            {completedSteps.length ? (
              <>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <CheckCircleOutlineIcon color="success" />
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{ color: "text.primary" }}
                  >
                    {t("pages.onboarding_overview.done")}
                  </Typography>
                </Stack>
                <Stack direction={"column"} spacing={1.5}>
                  <Box
                    sx={{
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.grey[300],
                      borderRadius: "12px",
                      p: 2,
                    }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      spacing={2}
                    >
                      <SummaryDetailBoxHeader
                        icon_type={"success"}
                        summary_title={
                          "pages.steps_details.business_details.title"
                        }
                        required={true}
                      />
                      {!isMobile && (
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{
                            color: "text.primary",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpenStep(
                              openStep === "BUSINESS_DETAILS"
                                ? null
                                : "BUSINESS_DETAILS"
                            );
                          }}
                        >
                          {t("pages.onboarding_overview.edit")}
                          {openStep === "BUSINESS_DETAILS" ? (
                            <KeyboardArrowUpIcon
                              fontSize="small"
                              sx={{
                                transform: "scale(0.8)",
                                verticalAlign: "middle",
                              }}
                            />
                          ) : (
                            <KeyboardArrowDownIcon
                              fontSize="small"
                              sx={{
                                transform: "scale(0.8)",
                                verticalAlign: "middle",
                              }}
                            />
                          )}
                        </Typography>
                      )}
                    </Stack>
                    {isMobile && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{
                            color: "text.primary",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpenStep(
                              openStep === "BUSINESS_DETAILS"
                                ? null
                                : "BUSINESS_DETAILS"
                            );
                          }}
                        >
                          {t("pages.onboarding_overview.edit")}
                          {openStep === "BUSINESS_DETAILS" ? (
                            <KeyboardArrowUpIcon
                              fontSize="small"
                              sx={{
                                transform: "scale(0.8)",
                                verticalAlign: "middle",
                              }}
                            />
                          ) : (
                            <KeyboardArrowDownIcon
                              fontSize="small"
                              sx={{
                                transform: "scale(0.8)",
                                verticalAlign: "middle",
                              }}
                            />
                          )}
                        </Typography>
                      </Box>
                    )}
                    {openStep === "BUSINESS_DETAILS" && (
                      <>
                        <Typography
                          variant="body2"
                          sx={{ my: "10px", color: "text.secondary" }}
                        >
                          {t("pages.steps_details.business_details.summary")}
                        </Typography>
                        <VerifyBusinessInfoFormQuestionnaire />
                      </>
                    )}
                  </Box>
                  {completedSteps.map((step, index) => (
                    <SummaryDetailBox
                      key={index}
                      icon_type="done"
                      label={step}
                      stepIndex={steps.indexOf(step)}
                      isSkipped={false}
                      openStep={openStep}
                      setOpenStep={setOpenStep}
                    />
                  ))}
                </Stack>
              </>
            ) : (
              ""
            )}
          </Stack>

          <Stack direction={"column"} spacing={2} sx={{ marginTop: "20px" }}>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <Checkbox
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                disabled={isChecked && company?.kycKybStatus !== "not_provided"}
              />
              <Typography
                variant="caption"
                sx={{
                  color: "text.primary",
                  display: "inline-block",
                  marginLeft: "8px",
                }}
              >
                {showFullText
                  ? t("pages.onboarding_overview.checkbox")
                  : `${t("pages.onboarding_overview.checkbox").slice(
                      0,
                      isMobile ? 100 : 300
                    )}... `}
                {t("pages.onboarding_overview.checkbox").length >
                  (isMobile ? 100 : 300) && (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => setShowFullText(!showFullText)}
                  >
                    {!showFullText && t("global.read_more")}
                  </Link>
                )}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mt: 6 }}
          >
            <Button
              id="back-button"
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => {
                localStorage.removeItem("onApplicationOverviewScreen");
                handleBack();
              }}
            >
              {t("global.back")}
            </Button>
            <Button
              id="register-button"
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={!allRequiredCompleted || !isChecked}
              onClick={async () => {
                if (company?.id) {
                  await connectSystemsDateMutation.mutateAsync({
                    id: company.id,
                  });
                  await updatekycKybStatus.mutateAsync({
                    companyIri: company["@id"]!,
                    status: "provided",
                  });
                  setCompany({ ...company, kycKybStatus: "provided" });

                  if (company.integrationType === "toothpick") {
                    Sentry.captureMessage(
                      `Redirecting to Toothpick as kycKybStatus changed to provided for ${company?.companyNo}.`
                    );
                    window.location.href =
                      integrationConfig.toothpickApiUrl +
                      "/erad/integration/finished?status=8";
                    return;
                  }

                  localStorage.removeItem("onApplicationOverviewScreen");

                  const companyNotEligible =
                    company?.hasActiveBankAccount === "no" ||
                    company?.hasBeenOperational === "no" ||
                    !company?.userRevenue ||
                    company?.userRevenue < minimumEligibleRevenue;

                  // If not eligible, simply return not eligible text.
                  if (companyNotEligible) {
                    navigate(PATH_ONBOARDING.notEligible);
                  } else {
                    navigate(PATH_ONBOARDING.underReview);
                  }
                }
              }}
            >
              {t("global.submit")}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

type SummaryDetailBoxHeaderType = {
  icon_type: any;
  summary_title: any;
  required: any;
};
const SummaryDetailBoxHeader = ({
  icon_type,
  summary_title,
  required,
}: SummaryDetailBoxHeaderType) => {
  const { t } = useTranslation(["translation"]);
  const isMobile = useIsMobile();
  return isMobile ? (
    <Stack
      direction={{ xs: "row", sm: "row" }}
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      sx={{ width: "100%", mb: 2 }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {icon_type === "info" ? (
          <InfoOutlinedIcon color="info" />
        ) : (
          <CheckCircleOutlineIcon color="success" />
        )}
        <Typography
          component="h1"
          variant="h5"
          sx={{ color: "text.primary", fontSize: "90%" }}
        >
          {t(summary_title)}
        </Typography>
      </Stack>
      {required ? (
        <Box
          sx={{
            backgroundColor: "rgba(2, 136, 209, 0.12)",
            padding: "0px 10px",
            borderRadius: "6px",
          }}
        >
          <Typography variant="caption" sx={{ color: "#0288D1" }}>
            {t("pages.onboarding_overview.required")}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            padding: "0px 10px",
            borderRadius: "6px",
          }}
        >
          <Typography variant="caption" sx={{ color: "#757575" }}>
            {t("pages.onboarding_overview.optional")}
          </Typography>
        </Box>
      )}
    </Stack>
  ) : (
    <Stack direction={"row"} alignItems={"flex-start"} spacing={1}>
      {icon_type === "info" ? (
        <InfoOutlinedIcon color="info" />
      ) : (
        <CheckCircleOutlineIcon color="success" />
      )}
      <Box>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography
            component="h1"
            variant="h5"
            sx={{ color: "text.primary" }}
          >
            {t(summary_title)}
          </Typography>
          {required ? (
            <Box
              sx={{
                backgroundColor: "rgba(2, 136, 209, 0.12)",
                padding: "0px 10px",
                borderRadius: "6px",
              }}
            >
              <Typography variant="caption" sx={{ color: "#0288D1" }}>
                {t("pages.onboarding_overview.required")}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: "#F5F5F5",
                padding: "0px 10px",
                borderRadius: "6px",
              }}
            >
              <Typography variant="caption" sx={{ color: "#757575" }}>
                {t("pages.onboarding_overview.optional")}
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

type SummaryDetailBoxType = {
  icon_type: string;
  label: CONNECTOR_STEPS;
  stepIndex: number;
  isSkipped: boolean;
  openStep: any;
  setOpenStep: any;
};
const SummaryDetailBox = ({
  icon_type,
  label,
  stepIndex,
  isSkipped,
  openStep,
  setOpenStep,
}: SummaryDetailBoxType) => {
  const { company } = useAuth();
  const { t } = useTranslation(["translation"]);
  const isMobile = useIsMobile();
  const { data: connectorTypeCategories } = useConnectorTypeCategoriesQuery();
  const isOpen = openStep === label;
  const { refetch: refetchConnectors } = useConnectorsQuery();

  const handleToggle = () => {
    setOpenStep(isOpen ? null : label);
  };

  const refreshConnectors = () => {
    refetchConnectors();
  };

  const getConnectorCategoryId = (step: CONNECTOR_STEPS) => {
    if (!connectorTypeCategories) return null;
    const foundCategory = connectorTypeCategories["hydra:member"]?.find(
      (category: any) => category.name === step
    );
    return foundCategory
      ? foundCategory["@id"]?.replace("/connector_type_categories/", "")
      : null;
  };

  const showConnector = () => {
    switch (label) {
      case CONNECTOR_STEPS.ADDITIONAL_COMPANY_DOCUMENTS:
        return <AdditionalDocumentsKSAForm navigation={false} />;
      case CONNECTOR_STEPS.COMPANY_DOCUMENTS:
        return <CompanyDocumentsFormSection navigation={false} />;
      case CONNECTOR_STEPS.BUSINESS_PREMISES:
        return <BusinessPremisesFormSection navigation={false} />;
      default: {
        const connectorTypeCategoryId = getConnectorCategoryId(label);
        if (connectorTypeCategoryId) {
          return (
            <ConnectorsByCategory
              connectorTypeCategoryId={connectorTypeCategoryId}
              refreshConnectors={refreshConnectors}
            />
          );
        }
        return null;
      }
    }
  };

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: (theme) => theme.palette.grey[300],
        borderRadius: "12px",
        p: 2,
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={2}
      >
        <SummaryDetailBoxHeader
          icon_type={icon_type}
          summary_title={
            label === CONNECTOR_STEPS.BUSINESS_PREMISES &&
            company?.revenueSegments?.includes("clinic")
              ? "pages.steps_details.business_premises.description_clinic"
              : stepsDetails[label].summary_title
          }
          required={stepsDetails[label].required}
        />
        {!isMobile && (
          <Typography
            component="span"
            variant="body2"
            sx={{
              color: "text.primary",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleToggle}
          >
            {t("pages.onboarding_overview.edit")}
            {isOpen ? (
              <KeyboardArrowUpIcon
                fontSize="small"
                sx={{ transform: "scale(0.8)", verticalAlign: "middle" }}
              />
            ) : (
              <KeyboardArrowDownIcon
                fontSize="small"
                sx={{ transform: "scale(0.8)", verticalAlign: "middle" }}
              />
            )}
          </Typography>
        )}
      </Stack>
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            component="span"
            variant="body2"
            sx={{
              color: "text.primary",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleToggle}
          >
            {t("pages.onboarding_overview.edit")}
            {isOpen ? (
              <KeyboardArrowUpIcon
                fontSize="small"
                sx={{ transform: "scale(0.8)", verticalAlign: "middle" }}
              />
            ) : (
              <KeyboardArrowDownIcon
                fontSize="small"
                sx={{ transform: "scale(0.8)", verticalAlign: "middle" }}
              />
            )}
          </Typography>
        </Box>
      )}
      {isOpen && <Box sx={{ mt: 2 }}>{showConnector()}</Box>}
    </Box>
  );
};

export default ApplicationOverview;
