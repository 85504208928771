import React, { Dispatch, SetStateAction } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type BankStatementConfirmationModalType = {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
};

export default function BankStatementConfirmationModal({
  opened,
  setOpened,
}: BankStatementConfirmationModalType) {
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        open={opened}
        maxWidth="sm"
        PaperProps={{ elevation: 1 }}
        onClose={() => {
          setOpened(false);
          localStorage.setItem("bankStatementCheck", "true");
        }}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: 5, px: 5, pb: 1.5 }}
        >
          <Typography variant="h3" id="form-dialog-title">
            {t("connectors.notifications.bank_statement_check.heading")}
          </Typography>

          <IconButton
            disableRipple={true}
            onClick={() => {
              setOpened(false);
              localStorage.setItem("bankStatementCheck", "true");
            }}
          >
            <Close sx={{ width: "20px", height: "20px" }} />
          </IconButton>
        </Stack>

        <DialogContent sx={{ mb: 5 }}>
          <DialogContentText
            variant="body2"
            id="form-dialog-description"
            sx={{ pb: 4, color: "text.secondary", border: "none" }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  "connectors.notifications.bank_statement_check.text2"
                ),
              }}
            />
            <br />
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: t("connectors.notifications.bank_statement_check.text"),
              }}
            />
          </DialogContentText>
          <Button
            type="button"
            variant="contained"
            size="large"
            onClick={() => {
              setOpened(false);
              localStorage.setItem("bankStatementCheck", "true");
            }}
          >
            {t("connectors.notifications.bank_statement_check.button_title")}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
