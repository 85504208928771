import useSettings from "../hooks/useSettings";

export const useSnapchatOAuth = () => {
  const { integrationConfig } = useSettings();

  const initiateSnapchatOAuth = () => {
    const redirect_uri = `${window.origin}/onboarding/connect-systems/custom-fivetran-connector/snapchat-ads_custom`;
    const queryParams = new URLSearchParams({
      response_type: "code",
      client_id: integrationConfig.snapchatAdsClientId,
      redirect_uri: redirect_uri,
      scope: "snapchat-marketing-api",
    });
    window.location.href = `https://accounts.snapchat.com/login/oauth2/authorize?${queryParams.toString()}`;
  };

  return { initiateSnapchatOAuth };
};
