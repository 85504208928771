import {
  Button,
  Stack,
  LinearProgress,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import FormikPhoneNumber from "../FormikPhoneNumber";
import { useEditCompanyDetail } from "../../queries/company";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import ViolationError from "../../utils/violationError";
import { CompanyDetailValues } from "../../types/Company";
import { PATH_ONBOARDING } from "../Routes/paths";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useIsMobile } from "../../hooks/useIsMobile";
import { isLngAR } from "../../utils/i18n";

export interface KsaFormProps {
  companyDetail: any;
  countryCode: any;
  clearCurrentCompany: () => void;
}
interface CompanyDetail {
  [key: string]: string | null | boolean;
}

const KsaForm = ({
  companyDetail,
  countryCode,
  clearCurrentCompany,
}: KsaFormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation", "countries"]);
  const isMobile = useIsMobile();
  const formikRef = useRef<FormikProps<CompanyDetailValues>>(null);
  const data = companyDetail;
  const { company, setCompany } = useAuth();
  const { mutate: editCompanyDetail } = useEditCompanyDetail();
  const [submitError, setSubmitError] = useState<null | string>(null);
  const renameKey = (obj: any, oldKey: string, newKey: string) => {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  };

  let crNumber = company?.registrationNumber ?? data.crNumber ?? "";
  let crName = company?.companyDetails?.name ?? data.crName ?? "";

  let webSite = company?.companyDetails?.webSite;
  if (!webSite) {
    if (data.address.general?.website) {
      webSite = data.address.general?.website;
    } else if (data.urls.length > 0) {
      webSite = data.urls[0]?.name;
    }
  }

  let paidInCapital = company?.companyDetails?.paidInCapital;
  if (!paidInCapital) {
    paidInCapital = data.capital?.paidAmount
      ? data.capital?.paidAmount.toLocaleString()
      : "";
  }

  let city = company?.companyDetails?.city;
  if (!city) {
    city = data.address.national?.city ?? "";
  }

  let phoneNumber = company?.phoneNumber;
  if (!phoneNumber) {
    phoneNumber = data.address.general?.telephone1
      ? `+966${data.address.general?.telephone1}`
      : "";
  }

  let area = company?.companyDetails?.area;
  if (!area) {
    area = data.address.national?.districtName ?? "";
  }

  let street = company?.companyDetails?.street;
  if (!street) {
    street = data.address.national?.streetName ?? "";
  }

  let buildingNumber = company?.companyDetails?.buildingNumber;
  if (!buildingNumber) {
    buildingNumber = data.address.national?.buildingNumber ?? "";
  }

  let activity = company?.companyDetails?.activity;
  if (!activity) {
    activity = data.activities?.description ?? "";
  }

  let taxNumber = company?.companyDetails?.taxNumber;
  if (!taxNumber) {
    taxNumber = data.crEntityNumber ?? "";
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        crNumber: crNumber,
        name: crName,
        activity: activity,
        paidInCapital: paidInCapital,
        webSite: webSite,
        city: city,
        phoneCode: "sa",
        phoneCodeDial: "966",
        phoneNumber: phoneNumber,
        area: area,
        street: street,
        buildingNumber: buildingNumber,
        registrationNumber: crNumber,
        taxNumber: taxNumber,
        countryCode: countryCode,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().trim().required(t("error.company.name.required")),
        paidInCapital: Yup.string()
          .trim()
          .required(t("error.company.paid_in_capital.required")),
        webSite: Yup.string()
          .trim()
          .required(t("error.company.website.required")),
        city: Yup.string().trim().required(t("error.company.city.required")),
        area: Yup.string().trim().required(t("error.company.area.required")),
        buildingNumber: Yup.string()
          .trim()
          .required(t("error.company.building_number.required")),
        taxNumber: Yup.string()
          .trim()
          .required(t("error.company.tax_number.required")),
        phoneCode: Yup.string()
          .trim()
          .nullable()
          .required("Phone code required."),
        phoneNumber: Yup.string()
          .trim()
          .required(t("error.user.phone_number.required"))
          .phone(t("error.user.phone_number.invalid")),
      })}
      onSubmit={(values, { setSubmitting, setStatus, setErrors }) => {
        values.phoneCode = values.phoneCode.toUpperCase();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const mainData = {
          address: data.address.general?.address,
          email: emailRegex.test(data.address.general?.email)
            ? data.address.general?.email
            : null,
        };
        const companyDetail: CompanyDetail = {
          legalName: data.crName,
          subscribedAmount: data.capital?.subscribedAmount,
          announcedAmount: data.capital?.announcedAmount,
          sharePrice: data.capital?.share?.sharePrice,
          sharesCount: data.capital?.sharesCount,
          expiryDate: data.expiryDate,
          issueDate: data.issueDate,
          crMainNumber: data.crMainNumber,
          cancellation: data.cancellation,
          zipCode: data.address.general?.zipcode,
          fax1: data.address.general?.fax1,
          phoneNumber2: data.address.general?.telephone2,
          postalBox1: data.address.general?.postalBox1,
          postalBox2: data.address.general?.postalBox2,
          additionalNumber: data.address.national?.additionalNumber,
          unitNumber: data.address.national?.unitNumber,
          isEcommerce: data.isEcommerce,
          locationId: data.location?.id,
          locationName: data.location?.name,
          companyStartDate: data.company?.startDate,
          companyPeriod: data.company?.period,
          companyEndDate: data.company?.endDate,
          businessTypeId: data.businessType?.id,
          businessTypeName: data.businessType?.name,
          fiscalYearMonth: data.fiscalYear?.month,
          fiscalYearDay: data.fiscalYear?.day,
          fiscalYearCalendarTypeId: data.fiscalYear?.calendarType?.id,
          fiscalYearCalendarTypeName: data.fiscalYear?.calendarType?.name,
          statusId: data.status?.id,
          name: data.status?.name,
          nameEn: data.status?.nameEn,
        };
        Object.keys(companyDetail).forEach((key: string) => {
          if (key === "isEcommerce")
            companyDetail[key] = companyDetail[key] ?? false;
          if (companyDetail[key] === "" || companyDetail[key] === undefined) {
            companyDetail[key] = null;
          }
        });
        const companyUrls = data.urls;
        const MAX_ATTRIBUTE_LENGTH = 255;
        const companyIsic = data.activities?.isic?.filter((obj: any) => {
          return (
            (obj.name?.length ?? 0) <= MAX_ATTRIBUTE_LENGTH &&
            (obj.nameEn?.length ?? 0) <= MAX_ATTRIBUTE_LENGTH
          );
        });
        companyIsic.forEach((obj: any) => renameKey(obj, "id", "isicId"));
        const companyParties: Object[] = [];
        data.parties.forEach((party: any) => {
          companyParties.push({
            name: party.name ?? null,
            birthDate: party.birthDate ?? null,
            sharesCount: party.sharesCount ?? null,
            gross: party.gross ?? null,
            identityId: party.identity?.id ?? null,
            identityType: party.identity?.type ?? null,
            relationId: party.relation?.id ?? null,
            relationName: party.relation?.name ?? null,
            nationalityId: party.nationality?.id ?? null,
            nationalityName: party.nationality?.name ?? null,
          });
        });

        // @todo find all the keys that we need to save from other steps.
        const dataToRetain = {
          monthlyRevenue: company?.monthlyRevenue,
          userRevenue: company?.userRevenue,
          isDropshipProduct: company?.isDropshipProduct,
          revenueSegments: company?.revenueSegments,
          fundingPurposes: company?.fundingPurposes,
          financingAmount: company?.companyDetails?.financingAmount,
          generatingProfit: company?.generatingProfit,
          hasActiveBankAccount: company?.hasActiveBankAccount,
          hasActiveLoans: company?.hasActiveLoans,
          hasBeenOperational: company?.hasBeenOperational,
          hasTradeLicense: company?.hasTradeLicense,
          loanRepaymentMonthlyInstallment:
            company?.loanRepaymentMonthlyInstallment,
        };

        const updatedCompanyData = {
          ...values,
          ...mainData,
          ...{
            companyDetails: {
              ...values,
              ...companyDetail,
              ...dataToRetain,
            },
          },
          ...{ companyIsic: companyIsic },
          ...{ companyParties: companyParties },
          ...{ companyUrls: companyUrls },
          ...dataToRetain,
        };
        updatedCompanyData.name = company?.name;

        editCompanyDetail(
          {
            companyIri: company ? company["@id"]! : "",
            values: updatedCompanyData,
          },
          {
            onError: (error) => {
              if (error instanceof ViolationError) {
                setStatus({
                  isValid: false,
                  msg: `${error.defaultErrorMsg}`,
                });
                setErrors(error.fields);
                setSubmitError(error.defaultErrorMsg);
                Sentry.captureMessage(
                  `[501001] KSA form submit field company: ${company?.companyNo} error:${error.defaultErrorMsg}.`,
                  {
                    extra: { error },
                  }
                );
              } else {
                setStatus({
                  isValid: false,
                  msg: `${error.message}`,
                });
                setSubmitError(error.message);
                Sentry.captureMessage(
                  `[501002] KSA form submit cr: ${values.registrationNumber} company: ${company?.companyNo} error: ${error.message}`,
                  {
                    extra: { error },
                  }
                );
              }
              setSubmitting(false);
            },
            onSuccess: (company) => {
              setCompany(company);
              navigate(PATH_ONBOARDING.additionalDocumentsKSA);
            },
          }
        );
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Field
            autoComplete="cr-number-nope"
            id="field-cr-number"
            type="text"
            name="crNumber"
            variant="standard"
            component={TextField}
            label={t("company.field.enter_your_crNumber")}
            fullWidth
            disabled={true}
          />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={3}
            sx={{ mb: 3, mt: 3 }}
          >
            <Field
              id="field-company-name"
              type="text"
              name="name"
              variant="standard"
              component={TextField}
              label={t("company.field.name")}
              fullWidth
              disabled={isSubmitting}
            />
            <Field
              id="field-activity"
              type="text"
              name="activity"
              variant="standard"
              component={TextField}
              label={t("company.field.activity")}
              fullWidth
              disabled={isSubmitting}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={3}
            sx={{ mb: 3 }}
          >
            <Field
              id="field-paid-in-capital"
              type="text"
              name="paidInCapital"
              variant="standard"
              component={TextField}
              label={t("company.field.paid_in_capital")}
              fullWidth
              disabled={isSubmitting}
            />
            <Field
              id="field-website"
              type="text"
              name="webSite"
              variant="standard"
              component={TextField}
              label={t("company.field.website")}
              fullWidth
              disabled={isSubmitting}
            />
          </Stack>
          <Stack direction="column" spacing={3} sx={{ mb: 3 }}>
            <Field
              id="field-city"
              type="text"
              name="city"
              variant="standard"
              component={TextField}
              label={t("company.field.city")}
              fullWidth
              disabled={isSubmitting}
            />
            <FormikPhoneNumber<CompanyDetailValues> />
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={3}
            sx={{ mb: 3 }}
          >
            <Field
              id="field-area"
              type="text"
              name="area"
              variant="standard"
              component={TextField}
              label={t("company.field.area")}
              fullWidth
              disabled={isSubmitting}
            />
            <Field
              id="field-street"
              type="text"
              name="street"
              variant="standard"
              component={TextField}
              label={t("company.field.street")}
              fullWidth
              disabled={isSubmitting}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={3}
            sx={{ mb: 3 }}
          >
            <Field
              id="field-building-number"
              type="text"
              name="buildingNumber"
              variant="standard"
              component={TextField}
              label={t("company.field.building_number")}
              fullWidth
              disabled={isSubmitting}
            />
            <Field
              id="field-tax-number"
              type="text"
              name="taxNumber"
              variant="standard"
              component={TextField}
              label={t("company.field.tax_number")}
              fullWidth
              disabled={isSubmitting}
            />
          </Stack>
          {isSubmitting && (
            <Box sx={{ mb: 3 }}>
              <LinearProgress />
            </Box>
          )}
          {submitError && (
            <Alert severity="error" variant="outlined" sx={{ mb: 2, mt: 2 }}>
              {submitError}
            </Alert>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 5,
              gap: isMobile ? 2 : "auto",
            }}
          >
            <Button
              id="back-button"
              color="inherit"
              variant="outlined"
              onClick={clearCurrentCompany}
              sx={{ mr: 1 }}
            >
              {t("global.back")}
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              variant="outlined"
              endIcon={isLngAR() ? <ArrowBackIcon /> : <ArrowForwardIcon />}
              id="continue-button"
              sx={{
                width: { xs: "100%", sm: "inherit" },
              }}
              startIcon={
                isSubmitting ? (
                  <CircularProgress size="1em" sx={{ color: "#fff" }} />
                ) : (
                  <></>
                )
              }
              disabled={isSubmitting || !isValid}
            >
              {t("global.continue")}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default KsaForm;
