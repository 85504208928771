import { Alert, Collapse, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  useConnectorsPost,
  useUpdateConnectorsConfigurationData,
} from "../../../queries/connector";
import { usePrivateFilesQuery } from "../../../queries/file";
import { Connector } from "../../../types/Connector";
import { PrivateFile } from "../../../types/PrivateFile";
import ViolationError from "../../../utils/violationError";
import FormikAutoSubmit from "../../FormikAutoSubmit";
import { UploadBankFile } from "../../Upload";
import BankStatementConfirmationModal from "./BankStatementConfirmationModal";
import {
  CounterComparator,
  createCounterComparator,
} from "../../../utils/counterComparator";

type BankAccountConnectorProps = {
  connector?: Connector;
  connectorType: string;
  isExpanded: boolean;
  refreshConnectors?: () => void;
};

type BankAccountConnectorFormProps = {
  files: Array<string> | Array<PrivateFile>;
};

export default function BankAccountConnector({
  connector: initialConnector,
  connectorType,
  isExpanded,
  refreshConnectors,
}: BankAccountConnectorProps) {
  const { t } = useTranslation();

  const [showNotification, setShowNotification] = useState(false);

  const { mutate: createConnector, isLoading: isCreating } =
    useConnectorsPost();

  const { mutate: updateConnectorConfigurationData, isLoading: isEditing } =
    useUpdateConnectorsConfigurationData();

  const privateFileQueries = usePrivateFilesQuery(
    initialConnector?.configurationData &&
      "files" in initialConnector.configurationData
      ? initialConnector.configurationData.files
      : []
  );
  const [connector, setConnector] = useState(initialConnector);
  const privateFilesLoading = privateFileQueries?.some(
    (privateFileQuery) => privateFileQuery.isLoading
  );
  const privateFilesError = privateFileQueries?.some(
    (privateFileQuery) => privateFileQuery.isError
  );
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  useEffect(() => {
    if (!privateFilesLoading) {
      setInitialLoadDone(true);
    }
  }, [privateFilesLoading]);

  if (!initialLoadDone && privateFilesLoading) {
    return null;
  }

  // Load all private files.
  let privateFiles = [] as PrivateFile[];
  if (!privateFilesLoading && !privateFilesError) {
    privateFiles = privateFileQueries?.map(
      (privateFileQuery) => privateFileQuery.data!
    );
  }

  const isPrivateFilesCounterIncreased: CounterComparator =
    createCounterComparator(privateFiles.length);

  if (privateFilesError) {
    return (
      <Typography variant="body1">
        {t("error.something_went_wrong.while_fetching")}
      </Typography>
    );
  }

  return (
    <Collapse in={isExpanded}>
      {!localStorage.getItem("bankStatementCheck") && (
        <BankStatementConfirmationModal
          opened={showNotification}
          setOpened={setShowNotification}
        />
      )}
      <Formik
        initialValues={
          {
            files: privateFiles,
          } as BankAccountConnectorFormProps
        }
        validationSchema={Yup.object().shape({
          files: Yup.array(),
        })}
        onSubmit={(values, { setStatus, setErrors, setSubmitting }) => {
          setErrors({});

          const filteredValues = {
            files: values.files.map((document: PrivateFile | string) =>
              typeof document === "string" ? document : document["@id"]!
            ),
          };

          if (connector) {
            updateConnectorConfigurationData(
              { connectorId: connector.id!, values: filteredValues },
              {
                onError: (error) => {
                  if (error instanceof ViolationError) {
                    setStatus({
                      isValid: false,
                      msg: `${error.defaultErrorMsg}`,
                    });
                    setErrors(error.fields);
                  } else {
                    setStatus({
                      isValid: false,
                      msg: `${error.message}`,
                    });
                  }
                },
                onSuccess: (data) => {
                  setConnector(data);
                  setStatus();
                  if (typeof refreshConnectors === "function") {
                    refreshConnectors();
                  }
                },
                onSettled: () => {
                  setSubmitting(false);
                  setShowNotification(
                    isPrivateFilesCounterIncreased(filteredValues.files.length)
                  );
                },
              }
            );
          } else {
            createConnector(
              { connectorType, values: filteredValues },
              {
                onError: (error) => {
                  if (error instanceof ViolationError) {
                    setStatus({
                      isValid: false,
                      msg: `${error.defaultErrorMsg}`,
                    });
                    setErrors(error.fields);
                  } else {
                    setStatus({
                      isValid: false,
                      msg: `${error.message}`,
                    });
                  }
                },
                onSuccess: (data) => {
                  setConnector(data);
                  if (typeof refreshConnectors === "function") {
                    refreshConnectors();
                  }
                },
                onSettled: () => {
                  setSubmitting(false);
                  setShowNotification(
                    isPrivateFilesCounterIncreased(filteredValues.files.length)
                  );
                },
              }
            );
          }
        }}
      >
        {({ isSubmitting, status }) => (
          <Form>
            {(isCreating || isEditing) && (
              <Box sx={{ mt: 1.5 }}>
                <LinearProgress />
              </Box>
            )}

            <UploadBankFile
              sxWrapper={{ mt: 1.5 }}
              fieldName="files"
              disabled={isSubmitting}
              maxFileSize={104857600}
              filesLimit={20}
              width="100%"
            />

            {status && (
              <Alert severity="error" variant="outlined" sx={{ mt: 3 }}>
                {status.msg}
              </Alert>
            )}

            <FormikAutoSubmit debounceMs={1000} />
          </Form>
        )}
      </Formik>
    </Collapse>
  );
}
