import useSettings from "../hooks/useSettings";

export const useXeroOAuth = () => {
  const { integrationConfig } = useSettings();

  const initiateXeroOAuth = () => {
    const redirect_uri = `${window.origin}/connector-redirect-uri/custom`;
    const queryParams = new URLSearchParams({
      client_id: integrationConfig.xeroClientId,
      response_type: "code",
      redirect_uri: redirect_uri,
      scope: "openid profile email accounting.transactions",
      state: localStorage.getItem("connectorRedirectUriConnectorId") ?? "",
    });
    window.location.href = `https://login.xero.com/identity/connect/authorize?${queryParams.toString()}`;
  };

  return { initiateXeroOAuth };
};
