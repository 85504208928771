import { Box, Button, Card, Container, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  useConnectorsQuery,
  useConnectorTypeCategoriesQuery,
} from "../../queries/connector";
import { PATH_ONBOARDING, PATH_AUTH } from "../Routes/paths";
import { isLngAR } from "../../utils/i18n";
import useAuth from "../../hooks/useAuth";
import { useState, useEffect } from "react";
import {
  CONNECTOR_STEPS,
  steps,
  stepsDetails,
  getStepsToShowFromSegments,
} from "./ConnectorTypeCategory";
import Stepper from "@mui/material/Stepper";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConnectorsByCategoryV2 from "../ConnectorsByCategoryV2";
import BusinessPremises from "../OnboardingCompanyDetail/BusinessPremises";
import CompanyDocuments from "../OnboardingCompanyDetail/CompanyDocuments";

export { steps, stepsDetails };

export const Dot = styled("span", {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean; width?: string }>(({ active, width }) => ({
  width: "100%",
  height: 4,
  background: `linear-gradient(to right, rgba(5,241,216,1) 0%, rgba(5,241,216,1) ${width}, rgba(224,224,224,1) ${width})`,
  borderRadius: "3px",
  marginTop: "13px",
}));

const OnboardingConnectSystems = () => {
  const { t } = useTranslation(["translation", "countries"]);
  const navigate = useNavigate();
  const { data: connectors, refetch: refetchConnectors } = useConnectorsQuery();
  const { data: connectorTypeCategories } = useConnectorTypeCategoriesQuery();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [connectorTypeIds, setConnectorTypeIds] = useState<string[]>([]);
  const { company } = useAuth();
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const [stepsToShow, setStepsToShow] = useState<CONNECTOR_STEPS[]>([]);
  const [numOfSteps, setNumOfSteps] = useState(0);

  const refreshConnectors = () => {
    refetchConnectors();
  };

  if (company?.revenueSegments?.length === 0) {
    localStorage.removeItem("lastStep1Page");
    localStorage.removeItem("lastStep2Page");
    navigate(PATH_AUTH.tellAboutBusiness);
  }

  let currentStepFromStorage = localStorage.getItem("lastStep2Page");
  if (currentStepFromStorage === "NaN") {
    currentStepFromStorage = "";
    localStorage.removeItem("lastStep2Page");
  }
  if (
    currentStepFromStorage &&
    parseInt(currentStepFromStorage, 10) !== activeStep
  ) {
    setActiveStep(parseInt(currentStepFromStorage, 10));
  }

  const setActiveStepWithStorage = (activeStep: number) => {
    if (activeStep.toString() === "NaN") {
      return;
    }

    localStorage.setItem("lastStep2Page", activeStep.toString());
    setActiveStep(activeStep);
  };

  useEffect(() => {
    const connectorRedirectUriConnectorId = localStorage.getItem(
      "connectorRedirectUriConnectorId"
    );
    if (connectorRedirectUriConnectorId) {
      setActiveStepWithStorage(Number(connectorRedirectUriConnectorId));
      localStorage.removeItem("connectorRedirectUriConnectorId");
    }
  }, []);

  useEffect(() => {
    const segments = company?.revenueSegments || [];
    setStepsToShow(getStepsToShowFromSegments(segments));
    setNumOfSteps(stepsToShow.length);
  }, [company?.revenueSegments, stepsToShow.length]);

  useEffect(() => {
    if (connectorTypeCategories) {
      const ids = connectorTypeCategories["hydra:member"]
        .filter((category: any) => stepsToShow.includes(category["name"]))
        .sort(
          (a: any, b: any) =>
            stepsToShow.indexOf(a["name"]) - stepsToShow.indexOf(b["name"])
        )
        .map((category: any) =>
          category["@id"].replace("/connector_type_categories/", "")
        );
      setConnectorTypeIds(ids);
    }
  }, [connectorTypeCategories, stepsToShow]);

  useEffect(() => {
    let todoSteps = [...stepsToShow];
    let skipped = new Set<number>();

    if (connectors && connectorTypeCategories) {
      stepsToShow.map((step) => {
        const connectorTypeCategory = connectorTypeCategories[
          "hydra:member"
        ].find((category) => category.name === step);
        if (
          connectors["hydra:member"].some(
            (connector) =>
              connector.isConnected &&
              connectorTypeCategory?.enabledConnectorTypes?.includes(
                connector.connectorType ?? ""
              )
          )
        ) {
          todoSteps = todoSteps.filter((todoStep) => todoStep !== step);
        }
      });
    }
    skipped = new Set(
      todoSteps
        .map((step) => stepsToShow.indexOf(step))
        .filter((step) => step >= 0)
    );
    setSkipped(skipped);
  }, [connectors, connectorTypeCategories, company, stepsToShow]);

  useEffect(() => {
    const handleLocalStorageUpdate = () => {
      setPasswordEmpty(
        Object.keys(localStorage).some((key) =>
          key.startsWith("password-empty")
        )
      );
    };

    window.addEventListener("localStorageUpdated", handleLocalStorageUpdate);

    handleLocalStorageUpdate();

    return () => {
      window.removeEventListener(
        "localStorageUpdated",
        handleLocalStorageUpdate
      );
    };
  }, []);

  useEffect(() => {
    if (activeStep + 1 === stepsToShow.length) {
      localStorage.setItem("VerifyBusinessStep", "true");
      navigate(PATH_ONBOARDING.connectSystems);
    } else {
      localStorage.removeItem("VerifyBusinessStep");
      navigate(PATH_ONBOARDING.connectSystems);
    }
  }, [activeStep, navigate, stepsToShow.length]);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    const nextStep = activeStep + 1;
    if (nextStep === stepsToShow.length) {
      if (company?.countryCode === "SA") {
        navigate(PATH_ONBOARDING.verification);
        return null;
      } else {
        navigate(PATH_ONBOARDING.applicationOverview);
        return null;
      }
    }
    setActiveStepWithStorage(nextStep);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      localStorage.setItem("backFromConnectorStep", "true");
      navigate(PATH_AUTH.tellAboutBusiness);
    } else {
      setActiveStepWithStorage(activeStep - 1);
    }
  };

  if (activeStep === stepsToShow.length) {
    navigate(PATH_ONBOARDING.applicationOverview);
    return null;
  }

  return (
    <Container maxWidth="lg" sx={{ my: { sm: 5 }, pr: 0 }} disableGutters>
      <Card sx={{ mb: 6, p: 4 }}>
        <Box sx={{ width: "100%" }}>
          {activeStep < numOfSteps && (
            <Stepper activeStep={activeStep} sx={{ display: "grid" }}>
              {stepsToShow.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return activeStep === index ? (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                    key={label}
                  >
                    <InfoOutlinedIcon color="info" />
                    <Typography
                      component="h1"
                      variant="h5"
                      sx={{ color: "text.primary" }}
                    >
                      {t(stepsDetails[label].summary_title)}
                    </Typography>
                  </Stack>
                ) : null;
              })}
              <Dot
                active={true}
                width={`${(100 * (activeStep + 1)) / numOfSteps}%`}
              />
            </Stepper>
          )}
          <>
            {activeStep < numOfSteps ? (
              stepsToShow[activeStep] === CONNECTOR_STEPS.BUSINESS_PREMISES ? (
                <BusinessPremises
                  handleNext={handleNext}
                  handleBack={handleBack}
                  activeStep={activeStep}
                />
              ) : stepsToShow[activeStep] ===
                CONNECTOR_STEPS.COMPANY_DOCUMENTS ? (
                <CompanyDocuments
                  handleNext={handleNext}
                  handleBack={handleBack}
                  activeStep={activeStep}
                />
              ) : (
                <>
                  <ConnectorSteps
                    connectorTypeCategoryId={connectorTypeIds[activeStep] || ""}
                    refreshConnectors={refreshConnectors}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pt: 2,
                      gap: { xs: 2, sm: "auto" },
                    }}
                  >
                    <Button
                      color="inherit"
                      variant="outlined"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      {t("global.back")}
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button
                      onClick={handleNext}
                      variant="outlined"
                      disabled={passwordEmpty && activeStep === 0}
                      endIcon={
                        isLngAR() ? <ArrowBackIcon /> : <ArrowForwardIcon />
                      }
                      sx={{
                        width: { xs: "100%", sm: "auto" },
                      }}
                    >
                      {skipped.has(activeStep)
                        ? t("global.skip")
                        : t("global.continue")}
                    </Button>
                  </Box>
                </>
              )
            ) : null}
          </>
        </Box>
      </Card>
    </Container>
  );
};

type ConnectorStepsType = {
  connectorTypeCategoryId: string;
  refreshConnectors: () => void;
};
const ConnectorSteps = ({
  connectorTypeCategoryId,
  refreshConnectors,
}: ConnectorStepsType) => {
  return (
    <Container
      maxWidth="lg"
      sx={{ my: { xs: 3, sm: 5 }, pr: 0 }}
      disableGutters
    >
      <ConnectorsByCategoryV2
        connectorTypeCategoryId={connectorTypeCategoryId}
        refreshConnectors={refreshConnectors}
      />
    </Container>
  );
};
export default OnboardingConnectSystems;
