import { Button, Stack, Typography, Input } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { NumericFormat } from "react-number-format";
import {
  useUpdateAverageMonthlyRevenuePost,
  useUpdateGeneratingProfitPost,
  useUpdateHasTradeLicensePost,
  useUpdateHasActiveBankAccountPost,
  useUpdateHasBeenOperationalPost,
  useUpdateHasActiveLoansPost,
  useUpdateLoanRepaymentMonthlyInstallmentPost,
} from "../../queries/businessInfo";
import { RevenueOptionItemType } from "../../types/VerifyBusinessInfoValues";

export const generateRevenueOptions = () => {
  const options = [
    { label: "", min: 0, max: 0 },
    { label: "1-10k", min: 1, max: 10 },
    { label: "10k-200k", min: 10, max: 200 },
    { label: "200k-500k", min: 200, max: 500 },
    { label: "500k-1m", min: 500, max: 1000 },
    { label: "1m+", min: 1000, max: Infinity },
  ];

  return options.map((option, index) => {
    const value = option.max === Infinity ? option.min * 2 : option.max;
    let possibleFinancing = 50;
    if (option.max === Infinity) {
      possibleFinancing = option.min * 1.5;
    } else if (option.max > 10) {
      possibleFinancing = (option.min + option.max) / 2;
    }

    return {
      min: option.min,
      max: option.max,
      label: option.label,
      value: value,
      possibleFinancing: Math.round(possibleFinancing),
    };
  });
};
export const revenueOptions: RevenueOptionItemType[] = generateRevenueOptions();
export const getRevenueOptionDetails = (value: number) => {
  const result = revenueOptions.find((option) => option.value === value);
  return result ?? revenueOptions[0];
};

const getRevenueFromUserRevenue = (value: number) => {
  let userRevenue = Math.round(value / 1000);
  const result = revenueOptions.find(
    (option) => option.min <= userRevenue && option.max >= userRevenue
  );
  if (result) {
    return result.value;
  }
  return revenueOptions[0].value;
};

const yesNoButtonsStyle = (isActive: boolean) => ({
  width: { xs: "100%", sm: "100px" },
  height: "30px",
  borderRadius: "25px",
  backgroundColor: isActive ? "#2E7D320A" : "transparent",
  color: isActive ? "#062F41" : "#00000059",
  border: isActive ? "1px solid #4CAF50" : "1px solid #0000001F",
  "&:hover": {
    backgroundColor: isActive ? "#2E7D320A" : "grey.200",
  },
});

interface YesNoQuestionProps {
  question: string;
  answer: string;
  handleChange: (value: string) => void;
}

const YesNoQuestion = ({
  question,
  answer,
  handleChange,
}: YesNoQuestionProps) => {
  const { t } = useTranslation(["translation", "countries"]);
  return (
    <Stack
      my={"24px"}
      spacing={"12px"}
      sx={{ backgroundColor: "#FAFAFA", p: 2 }}
    >
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {question}
      </Typography>
      <Stack direction="row" spacing={"10px"} sx={{ mt: 1 }}>
        <Button
          variant="contained"
          onClick={() => handleChange("yes")}
          sx={yesNoButtonsStyle(answer === "yes")}
        >
          {t("global.yes")}
        </Button>
        <Button
          variant="contained"
          onClick={() => handleChange("no")}
          sx={yesNoButtonsStyle(answer === "no")}
        >
          {t("global.no")}
        </Button>
      </Stack>
    </Stack>
  );
};

export const VerifyBusinessInfoFormQuestionnaire = () => {
  const { t } = useTranslation(["translation", "countries"]);
  const { company, setCompany } = useAuth();

  const [generatingProfit, setGeneratingProfit] = useState<string>(
    company?.generatingProfit ?? "no"
  );
  const [hasTradeLicense, setHasTradeLicense] = useState<string>(
    company?.hasTradeLicense ?? "no"
  );
  const [hasActiveBankAccount, setHasActiveBankAccount] = useState<string>(
    company?.hasActiveBankAccount ?? "no"
  );
  const [hasBeenOperational, setHasBeenOperational] = useState<string>(
    company?.hasBeenOperational ?? "no"
  );
  const [hasActiveLoans, setHasActiveLoans] = useState<string>(
    company?.hasActiveLoans ?? "no"
  );
  const [, setLoanRepaymentMonthlyInstallment] = useState<string>(
    company?.loanRepaymentMonthlyInstallment ?? "0"
  );

  const { mutate: verifyAverageMonthlyRevenueInfo } =
    useUpdateAverageMonthlyRevenuePost();

  const { mutate: verifyGeneratingProfitInfo } =
    useUpdateGeneratingProfitPost();

  const { mutate: verifyHasTradeLicenseInfo } = useUpdateHasTradeLicensePost();

  const { mutate: verifyHasActiveBankAccountInfo } =
    useUpdateHasActiveBankAccountPost();

  const { mutate: verifyHasBeenOperationalInfo } =
    useUpdateHasBeenOperationalPost();

  const { mutate: verifyHasActiveLoansInfo } = useUpdateHasActiveLoansPost();

  const { mutate: verifyLoanRepaymentMonthlyInstallmentInfo } =
    useUpdateLoanRepaymentMonthlyInstallmentPost();

  const handleMonthlyRevenueQuestionChange = (value: string) => {
    const revenue: number = parseFloat(value.replaceAll(",", ""));
    const monthlyRevenue = getRevenueFromUserRevenue(revenue);

    verifyAverageMonthlyRevenueInfo({
      userRevenue: revenue,
      monthlyRevenue: monthlyRevenue,
    });

    setCompany({
      ...company,
      userRevenue: revenue,
      monthlyRevenue: monthlyRevenue,
    });
  };

  const handleProfitQuestionChange = (value: string) => {
    setGeneratingProfit(value);
    verifyGeneratingProfitInfo({ generatingProfit: value });
    setCompany({
      ...company,
      generatingProfit: value,
    });
  };

  const handleTradeLicenseQuestionChange = (value: string) => {
    setHasTradeLicense(value);
    verifyHasTradeLicenseInfo({ hasTradeLicense: value });
    setCompany({
      ...company,
      hasTradeLicense: value,
    });
  };

  const handleActiveBankQuestionChange = (value: string) => {
    setHasActiveBankAccount(value);
    verifyHasActiveBankAccountInfo({
      hasActiveBankAccount: value,
    });
    setCompany({
      ...company,
      hasActiveBankAccount: value,
    });
  };

  const handleOperationalPeriodQuestionChange = (value: string) => {
    setHasBeenOperational(value);
    verifyHasBeenOperationalInfo({ hasBeenOperational: value });
    setCompany({
      ...company,
      hasBeenOperational: value,
    });
  };

  const handleActiveLoanQuestionChange = (value: string) => {
    setHasActiveLoans(value);
    verifyHasActiveLoansInfo({ hasActiveLoans: value });
    setCompany({
      ...company,
      hasActiveLoans: value,
    });
  };

  const handleLoanRepaymentMonthlyInstallmentQuestionChange = (
    value: string
  ) => {
    setLoanRepaymentMonthlyInstallment(value);
    verifyLoanRepaymentMonthlyInstallmentInfo({
      loanRepaymentMonthlyInstallment: value,
    });
    setCompany({
      ...company,
      loanRepaymentMonthlyInstallment: value,
    });
  };

  return (
    <>
      <Stack
        my={"24px"}
        spacing={"3px"}
        sx={{ backgroundColor: "#FAFAFA", p: 2 }}
      >
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {t("pages.verify_your_business_info.monthly_revenue_question")}
        </Typography>
        <NumericFormat
          value={company?.userRevenue || null}
          type="text"
          prefix={company?.currency + " "}
          customInput={Input}
          sx={{
            width: { xs: "100%", sm: "420px" },
          }}
          onChange={(event) =>
            handleMonthlyRevenueQuestionChange(
              event.target.value.replace(company?.currency + " ", "")
            )
          }
        />
      </Stack>

      <YesNoQuestion
        question={t("pages.verify_your_business_info.profit_question")}
        answer={generatingProfit}
        handleChange={handleProfitQuestionChange}
      />

      <YesNoQuestion
        question={t("pages.verify_your_business_info.trade_license_question")}
        answer={hasTradeLicense}
        handleChange={handleTradeLicenseQuestionChange}
      />

      <YesNoQuestion
        question={t("pages.verify_your_business_info.active_bank_question")}
        answer={hasActiveBankAccount}
        handleChange={handleActiveBankQuestionChange}
      />

      <YesNoQuestion
        question={t(
          "pages.verify_your_business_info.operational_period_question"
        )}
        answer={hasBeenOperational}
        handleChange={handleOperationalPeriodQuestionChange}
      />

      <Stack
        my={"24px"}
        spacing={"12px"}
        sx={{ backgroundColor: "#FAFAFA", p: 2 }}
      >
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {t("pages.verify_your_business_info.active_loan_question")}
        </Typography>
        <Stack direction="column" spacing={"12px"}>
          <Stack direction="row" spacing={"10px"} sx={{ mt: 1 }}>
            <Button
              variant="contained"
              onClick={() => handleActiveLoanQuestionChange("yes")}
              sx={yesNoButtonsStyle(hasActiveLoans === "yes")}
            >
              {t("global.yes")}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleActiveLoanQuestionChange("no")}
              sx={yesNoButtonsStyle(hasActiveLoans === "no")}
            >
              {t("global.no")}
            </Button>
          </Stack>

          {hasActiveLoans === "yes" && (
            <>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {t(
                  "pages.verify_your_business_info.monthly_installment_value_question"
                )}
              </Typography>
              <NumericFormat
                value={
                  company?.loanRepaymentMonthlyInstallment
                    ? company?.loanRepaymentMonthlyInstallment
                    : null
                }
                type="text"
                prefix={company?.currency + " "}
                customInput={Input}
                sx={{
                  width: { xs: "100%", sm: "420px" },
                }}
                onChange={(event) =>
                  handleLoanRepaymentMonthlyInstallmentQuestionChange(
                    event.target.value
                  )
                }
              />
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export type VerifyBusinessFormsExtendedType = {
  currentPage: number;
  handleChangePage: (new_page: number) => void;
};

const VerifyBusinessInfoForm = ({
  handleChangePage,
  currentPage,
}: VerifyBusinessFormsExtendedType) => {
  const { t } = useTranslation(["translation", "countries"]);
  localStorage.removeItem("backFromConnectorStep");
  return (
    <>
      <Typography variant="h3" component="h1" sx={{ mb: 2 }}>
        {t("pages.verify_your_business_info.heading")}
      </Typography>
      <Typography variant="body2" sx={{ color: "text.secondary", mb: "32px" }}>
        {t("pages.verify_your_business_info.details_about_business")}
      </Typography>
      <VerifyBusinessInfoFormQuestionnaire />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={5}
        sx={{ mt: 6 }}
      >
        <Button
          id="back-button"
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => handleChangePage(currentPage - 1)}
        >
          {t("global.back")}
        </Button>
        <Button
          id="register-button"
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleChangePage(currentPage + 1)}
          sx={{
            width: { xs: "100%", sm: "auto" },
          }}
        >
          {t("global.continue")}
        </Button>
      </Stack>
    </>
  );
};

export default VerifyBusinessInfoForm;
