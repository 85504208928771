import {
  Button,
  Card,
  Container,
  Stack,
  Typography,
  Box,
  LinearProgress,
  CircularProgress,
  Alert,
  alpha,
  Link,
  Stepper,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH_ONBOARDING } from "../Routes/paths";
import { Form, Formik } from "formik";
import { KycKybValues } from "../OnboardingVerification/KycKybForm";
import useAuth from "../../hooks/useAuth";
import { useKycKybPost } from "../../queries/kycKyb";
import * as Yup from "yup";
import { KycKybDocumentsValues } from "../../types/FormValues";
import {
  DEFAULT_CERTIFICATION_MIME_TYPES,
  DEFAULT_CONSENT_MIME_TYPES,
} from "../../config/config";
import { PrivateFile } from "../../types/PrivateFile";
import { UploadMultiFile } from "../Upload";
import ViolationError from "../../utils/violationError";
import PoWNotificationModal from "../OnboardingVerification/PoWNotificationModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { isLngAR } from "../../utils/i18n";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Dot } from "../OnboardingConnectSystems";

type AdditionalDocumentsKSAFormType = {
  navigation?: boolean;
};
export const AdditionalDocumentsKSAForm = ({
  navigation,
}: AdditionalDocumentsKSAFormType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, company, setCompany } = useAuth();
  const countryCode = company?.countryCode;
  const isMobile = useIsMobile();

  const { mutate: kycKybPost } = useKycKybPost();
  const [PoWModalOpened, setPoWModalOpened] = useState(false);

  if (countryCode !== "SA") {
    navigate(PATH_ONBOARDING.applicationOverview);
    return null;
  }

  if (company?.registrationNumber === "") {
    navigate(PATH_ONBOARDING.applicationOverview);
    return null;
  }

  const initialValuesKsa = {
    articlesOfAssociation:
      company?.kycKybDocuments?.articlesOfAssociation ?? [],
    companyConsent: company?.kycKybDocuments?.companyConsent ?? [],
    shareholderConsent: company?.kycKybDocuments?.shareholderConsent ?? [],
    vatCertificate: company?.kycKybDocuments?.vatCertificate ?? [],
    gosiCertificate: company?.kycKybDocuments?.gosiCertificate ?? [],
  } as KycKybValues;

  return (
    <Formik
      validateOnMount={true}
      initialValues={initialValuesKsa}
      validationSchema={Yup.object().shape({
        articlesOfAssociation: Yup.array()
          .max(20, () => {
            return t(
              "error.company.kyc_kyb_documents.articles_of_association.max",
              {
                limit: 20,
              }
            );
          })
          .mime(
            Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES),
            t("error.general.mime", {
              types: Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES).concat(", "),
            })
          ),
        companyConsent: Yup.array()
          .required(
            t("error.company.kyc_kyb_documents.company_consent.required")
          )
          .mime(
            Object.keys(DEFAULT_CONSENT_MIME_TYPES),
            t("error.general.mime", {
              types: Object.keys(DEFAULT_CONSENT_MIME_TYPES).concat(", "),
            })
          ),
        shareholderConsent: Yup.array()
          .required(
            t("error.company.kyc_kyb_documents.shareholder_consent.required")
          )
          .mime(
            Object.keys(DEFAULT_CONSENT_MIME_TYPES),
            t("error.general.mime", {
              types: Object.keys(DEFAULT_CONSENT_MIME_TYPES).concat(", "),
            })
          ),
        vatCertificate: Yup.array().mime(
          Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES),
          t("error.general.mime", {
            types: Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES).concat(", "),
          })
        ),
        gosiCertificate: Yup.array().mime(
          Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES),
          t("error.general.mime", {
            types: Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES).concat(", "),
          })
        ),
      })}
      onSubmit={(
        values,
        { setStatus, setErrors, setFieldError, setSubmitting }
      ) => {
        const valuesKycKybonly = {
          companyConsent: values.companyConsent,
          shareholderConsent: values.shareholderConsent,
          articlesOfAssociation: values.articlesOfAssociation,
          vatCertificate: values.vatCertificate,
          gosiCertificate: values.gosiCertificate,
        };

        const filteredValues = {} as KycKybDocumentsValues;
        for (const [documentGroup, documents] of Object.entries(
          valuesKycKybonly
        )) {
          filteredValues[documentGroup as keyof KycKybValues] = documents.map(
            (document: PrivateFile) => document["@id"]!
          );
        }

        kycKybPost(
          {
            companyIri: user?.company!,
            values: filteredValues,
          },
          {
            onError: (error) => {
              if (error instanceof ViolationError) {
                setStatus({
                  isValid: false,
                  msg: `${error.defaultErrorMsg}`,
                });
                setErrors(error.fields);
              } else {
                setStatus({
                  isValid: false,
                  msg: `${error.message}`,
                });
              }

              setSubmitting(false);
            },
            onSuccess: async (data) => {
              setCompany(data);
              navigate(PATH_ONBOARDING.applicationOverview);
            },
          }
        );
      }}
    >
      {({ values, errors, isSubmitting, isValid, status }) => (
        <Form>
          <PoWNotificationModal
            opened={PoWModalOpened}
            setOpened={setPoWModalOpened}
          />

          <Stack spacing={1} justifyContent="space-between">
            <Box
              sx={{
                fontSize: "1.25em",
                fontWeight: "600",
                lineHeight: "2",
              }}
            >
              {t("pages.deployment.simah_heading")}{" "}
              <Box
                component="img"
                sx={{
                  maxWidth: "100%",
                  maxHeight: "4em",
                  lineHeight: "4",
                  verticalAlign: "middle",
                }}
                src={`/images/simah.svg`}
                alt="Simah"
              />
            </Box>
            <Stack direction="column" justifyContent="space-between">
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h4" sx={{ mb: 0.5 }}>
                    {t("pages.verification.company_consent.heading")}{" "}
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{
                        borderRadius: "6px",
                        padding: 1,
                        display: "inlineBlock",
                        boxSizing: "border-box",
                        minHeight: "24px",
                        textTransform: "capitalize",
                        color: "info.main",
                        width: "auto",
                        backgroundColor: (theme) =>
                          alpha(theme.palette.info.main, 0.12),
                      }}
                      marginLeft={1}
                    >
                      {t("global.required")}
                    </Typography>
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {t("pages.verification.company_consent.subheading")}
                  </Typography>
                </Box>

                <Link
                  href="/OBIC - Commercial Consent.pdf"
                  target="_blank"
                  sx={{
                    fontSize: "0.75em",
                    color: "text.primary",
                    borderRadius: "2em",
                    border: "1px solid",
                    margin: { xs: "0", sm: "0 0 3em" },
                    my: { xs: 2 },
                    padding: "0.5em 1em",
                    display: "inline-block",
                    width: "fit-content",
                  }}
                >
                  {t("global.download_copy")}
                </Link>
              </Stack>

              <UploadMultiFile
                sxWrapper={{ mb: 3 }}
                fieldName="companyConsent"
                disabled={isSubmitting}
                acceptedFileTypes={DEFAULT_CONSENT_MIME_TYPES}
                filesLimit={20}
                isInitialFiles={initialValuesKsa.companyConsent}
              />
            </Stack>

            <Stack direction={"column"} justifyContent="space-between">
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h4" sx={{ mb: 0.5 }}>
                    {t("pages.verification.shareholder_consent.heading")}{" "}
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{
                        borderRadius: "6px",
                        padding: 1,
                        display: "inlineBlock",
                        boxSizing: "border-box",
                        minHeight: "24px",
                        textTransform: "capitalize",
                        color: "info.main",
                        width: "auto",
                        backgroundColor: (theme) =>
                          alpha(theme.palette.info.main, 0.12),
                      }}
                      marginLeft={1}
                    >
                      {t("global.required")}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", mb: 2 }}
                  >
                    {t("pages.verification.shareholder_consent.subheading")}
                  </Typography>
                </Box>

                <Link
                  href="/OBIC - Customer Individual Consent.pdf"
                  target="_blank"
                  sx={{
                    fontSize: "0.75em",
                    color: "text.primary",
                    borderRadius: "2em",
                    border: "1px solid",
                    margin: { xs: "0", sm: "0 0 3em" },
                    my: { xs: 2 },
                    padding: "0.5em 1em",
                    display: "inline-block",
                    width: "fit-content",
                  }}
                >
                  {t("global.download_copy")}
                </Link>
              </Stack>

              <Box sx={{ width: "100%" }}>
                <UploadMultiFile
                  sxWrapper={{ mb: 3 }}
                  fieldName="shareholderConsent"
                  disabled={isSubmitting}
                  acceptedFileTypes={DEFAULT_CONSENT_MIME_TYPES}
                  filesLimit={20}
                  isInitialFiles={initialValuesKsa.shareholderConsent}
                />
              </Box>
            </Stack>

            <Stack direction={"column"} justifyContent="space-between">
              <Typography variant="h4" sx={{ mb: 0.5 }}>
                {t("pages.verification.articles_of_association.heading")}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", mb: 2 }}
              >
                {t("pages.verification.articles_of_association.subheading")}
              </Typography>
              <UploadMultiFile
                sxWrapper={{ mb: 3 }}
                fieldName="articlesOfAssociation"
                disabled={isSubmitting}
                acceptedFileTypes={DEFAULT_CERTIFICATION_MIME_TYPES}
                filesLimit={20}
                isInitialFiles={initialValuesKsa.articlesOfAssociation}
              />
            </Stack>

            <Stack direction={"column"} justifyContent="space-between">
              <Typography variant="h4" sx={{ mb: 0.5 }}>
                {t("pages.verification.vat_certificate.heading")}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", mb: 2 }}
              >
                {t("pages.verification.vat_certificate.subheading")}
              </Typography>
              <UploadMultiFile
                sxWrapper={{ mb: 3 }}
                fieldName="vatCertificate"
                disabled={isSubmitting}
                acceptedFileTypes={DEFAULT_CERTIFICATION_MIME_TYPES}
                filesLimit={20}
                isInitialFiles={initialValuesKsa.vatCertificate}
              />
            </Stack>

            <Stack direction={"column"} justifyContent="space-between">
              <Typography variant="h4" sx={{ mb: 0.5 }}>
                {t("pages.verification.gosi_certificate.heading")}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", mb: 2 }}
              >
                {t("pages.verification.gosi_certificate.subheading")}
              </Typography>
              <UploadMultiFile
                sxWrapper={{ mb: 3 }}
                fieldName="gosiCertificate"
                disabled={isSubmitting}
                acceptedFileTypes={DEFAULT_CERTIFICATION_MIME_TYPES}
                filesLimit={20}
                isInitialFiles={initialValuesKsa.gosiCertificate}
              />
            </Stack>
          </Stack>

          {status && (
            <Alert severity="error" variant="outlined" sx={{ mb: 3 }}>
              {status.msg}
            </Alert>
          )}

          {isSubmitting && (
            <Box sx={{ mb: 3 }}>
              <LinearProgress />
            </Box>
          )}
          {navigation && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: isMobile ? 2 : "auto",
              }}
            >
              <Button
                id="back-button"
                color="inherit"
                variant="outlined"
                onClick={() => {
                  navigate(PATH_ONBOARDING.connectSystems);
                }}
                sx={{ mr: 1 }}
              >
                {t("global.back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                type="submit"
                variant="outlined"
                endIcon={isLngAR() ? <ArrowBackIcon /> : <ArrowForwardIcon />}
                id="continue-button"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size="1em" sx={{ color: "#fff" }} />
                  ) : (
                    <></>
                  )
                }
                sx={{
                  width: { xs: "100%", sm: "inherit" },
                }}
                disabled={isSubmitting || !isValid}
              >
                {t("global.continue")}
              </Button>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

const AdditionalDocumentsKSA = () => {
  const { t } = useTranslation();
  const { company } = useAuth();

  const stepProps: { completed?: boolean } = {};
  if (
    !(
      (company?.kycKybDocuments?.companyConsent ?? []).length > 0 &&
      (company?.kycKybDocuments?.shareholderConsent ?? []).length > 0
    )
  ) {
    stepProps.completed = false;
  }
  return (
    <Container
      maxWidth="lg"
      sx={{ my: { xs: 3, sm: 5 }, pr: 0 }}
      disableGutters
    >
      <Card sx={{ mb: 6, p: 4 }}>
        <Stepper sx={{ display: "grid", mb: 3 }}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <InfoOutlinedIcon color="info" />
            <Typography
              component="h1"
              variant="h5"
              sx={{ color: "text.primary" }}
            >
              {t("pages.steps_details.company_documents.title")}
            </Typography>
          </Stack>
          <Dot active={true} width={`100%`} />
        </Stepper>
        <Stack direction="column" spacing={1} justifyContent="space-between">
          <Box>
            <Typography variant="h3" sx={{ mb: 1.5 }}>
              {t("pages.deployment.heading")}
            </Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {t("pages.deployment.subheading_ksa")}
            </Typography>
          </Box>
          <AdditionalDocumentsKSAForm navigation={true} />
        </Stack>
      </Card>
    </Container>
  );
};

export default AdditionalDocumentsKSA;
