import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Stack,
  Collapse,
} from "@mui/material";
import { Form, Formik } from "formik";
import { UploadMultiFile } from "../Upload";
import { PrivateFile } from "../../types/PrivateFile";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { isLngAR } from "../../utils/i18n";
import { DEFAULT_ALLOWED_MIME_TYPES } from "../../config/config";
import { useEffect, useState } from "react";
import { useKycKybPost } from "../../queries/kycKyb";
import useAuth from "../../hooks/useAuth";
import { KycKybDocumentsValues } from "../../types/FormValues";
import { KycKybValues } from "../OnboardingVerification/KycKybForm";
import ViolationError from "../../utils/violationError";
import FormikAutoSubmit from "../FormikAutoSubmit";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useGetCompanyFullInfo } from "../../queries/company";
import KsaForm from "../OnboardingVerificationCountry/KsaForm";
import VerificationForm from "../OnboardingVerificationCountry/VerificationForm";

export const connectorBoxStyles = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const connectorBoxImageStyles = {
  display: "block",
  maxWidth: "67px",
  maxHeight: "67px",
};

type CompanyDocumentsFormSectionProps = {
  navigation: boolean;
  handleNext?: any;
  activeStep?: any;
  handleBack?: any;
};

export const CompanyDocumentsFormSection = ({
  navigation,
  handleNext,
  activeStep,
  handleBack,
}: CompanyDocumentsFormSectionProps) => {
  const { company, refetchCompany } = useAuth();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { mutate: kycKybPost } = useKycKybPost();
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentDocs = (company?.kycKybDocuments?.companyDocuments ??
    []) as PrivateFile[];
  const [isCsvConnectorExpanded, setIsCsvConnectorExpanded] = useState(
    currentDocs.length > 0
  );

  const isContinue = (values: { companyDocuments: PrivateFile[] }) => {
    return values.companyDocuments.length > 0 || currentDocs.length;
  };

  return (
    <>
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {t("pages.verification.company_documents.subheading")}
      </Typography>
      <Box mt={4}>
        <Formik
          initialValues={{
            companyDocuments: currentDocs,
          }}
          validationSchema={Yup.object().shape({
            companyDocuments: Yup.array().mime(
              Object.keys(DEFAULT_ALLOWED_MIME_TYPES),
              t("error.general.mime", {
                types: Object.keys(DEFAULT_ALLOWED_MIME_TYPES).concat(", "),
              })
            ),
          })}
          onSubmit={(values, { setStatus, setErrors, setSubmitting }) => {
            setIsSubmitting(true);
            const filteredValues = {} as KycKybDocumentsValues;
            for (const [documentGroup, documents] of Object.entries(values)) {
              filteredValues[documentGroup as keyof KycKybValues] =
                documents.map((document: PrivateFile) => document["@id"]!);
            }

            kycKybPost(
              { companyIri: user?.company!, values: filteredValues },
              {
                onError: (error) => {
                  if (error instanceof ViolationError) {
                    setStatus({
                      isValid: false,
                      msg: `${error.defaultErrorMsg}`,
                    });
                    setErrors(error.fields);
                  } else {
                    setStatus({
                      isValid: false,
                      msg: `${error.message}`,
                    });
                  }

                  setSubmitting(false);
                  setIsSubmitting(false);
                },
                onSuccess: () => {
                  refetchCompany();
                  setSubmitting(false);
                  setIsSubmitting(false);
                },
              }
            );
          }}
        >
          {({
            values,
            errors,
            isSubmitting: formikIsSubmitting,
            isValid,
            status,
          }) => (
            <Form>
              <Grid item xs={12} key={"csv-upload"}>
                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems="center"
                  gap={"16px"}
                >
                  <Box
                    onClick={() =>
                      setIsCsvConnectorExpanded(
                        (isCsvConnectorExpanded) => !isCsvConnectorExpanded
                      )
                    }
                    sx={{
                      ...connectorBoxStyles,
                      borderWidth: "1px",
                      m: "1px",
                      height: "80px",
                      borderStyle: "solid",
                      borderColor: (theme) =>
                        theme.palette.action.disabledBackground,
                      borderRadius: "8px",
                      cursor: "pointer",
                      "&:hover, &:focus": {
                        borderWidth: "1px",
                        borderColor: (theme) => theme.palette.primary.main,
                        backgroundColor: "#1976D20A",
                      },
                      marginBottom: 1,
                    }}
                  >
                    <Box
                      component="img"
                      src={`/images/connector-types/file-upload.png`}
                      alt="Connector type logo"
                      height={"45px"}
                    />
                    <Stack direction={"column"}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mt: 1.25,
                          ml: "20px",
                          fontSize: { xs: "80%", sm: "100%" },
                        }}
                      >
                        {t("connectors.csv_connector.trade_license")}
                      </Typography>
                      {!navigation && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "text.secondary",
                            ml: "20px",
                            fontSize: { sm: "80%" },
                          }}
                        >
                          {t("connectors.csv_connector.upload_trade_license")}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                  <Box
                    onClick={() =>
                      setIsCsvConnectorExpanded(
                        (isCsvConnectorExpanded) => !isCsvConnectorExpanded
                      )
                    }
                    sx={{
                      ...connectorBoxStyles,
                      borderWidth: "1px",
                      m: "1px",
                      height: "80px",
                      borderStyle: "solid",
                      borderColor: (theme) =>
                        theme.palette.action.disabledBackground,
                      borderRadius: "8px",
                      cursor: "pointer",
                      "&:hover, &:focus": {
                        borderWidth: "1px",
                        borderColor: (theme) => theme.palette.primary.main,
                        backgroundColor: "#1976D20A",
                      },
                      marginBottom: 1,
                    }}
                  >
                    <Box
                      component="img"
                      src={`/images/connector-types/file-upload.png`}
                      alt="Connector type logo"
                      height={"45px"}
                    />
                    <Stack direction={"column"}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mt: 1.25,
                          ml: "20px",
                          fontSize: { xs: "80%", sm: "100%" },
                        }}
                      >
                        {t("connectors.csv_connector.proof_of_ownership")}
                      </Typography>
                      {!navigation && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "text.secondary",
                            ml: "20px",
                            fontSize: { sm: "80%" },
                          }}
                        >
                          {t(
                            "connectors.csv_connector.upload_proof_of_ownership"
                          )}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                </Box>
              </Grid>

              <Collapse in={isCsvConnectorExpanded}>
                <UploadMultiFile
                  sxWrapper={{ mt: 1.5 }}
                  fieldName="companyDocuments"
                  disabled={isSubmitting}
                  maxFileSize={104857600}
                  filesLimit={20}
                />
              </Collapse>
              <FormikAutoSubmit debounceMs={1000} />
              {navigation && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 5,
                    gap: isMobile ? 2 : "auto",
                  }}
                >
                  <Button
                    color="inherit"
                    variant="outlined"
                    disabled={activeStep === 0 || isSubmitting}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t("global.back")}
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    type="submit"
                    onClick={handleNext}
                    variant="outlined"
                    endIcon={
                      isLngAR() ? <ArrowBackIcon /> : <ArrowForwardIcon />
                    }
                    sx={{
                      width: { xs: "100%", sm: "inherit" },
                    }}
                  >
                    {isContinue(values)
                      ? t("global.continue")
                      : t("global.skip")}
                  </Button>
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

type CompanyDocumentsFormKSAType = {
  activeStep?: any;
  handleBack?: any;
};

const CompanyDocumentsFormKSA = ({
  activeStep,
  handleBack,
}: CompanyDocumentsFormKSAType) => {
  const { t } = useTranslation();
  const [companyDetail, setCompanyDetail] = useState<{} | null>();
  const { mutate: getCompanyFullInfo } = useGetCompanyFullInfo();
  const { company } = useAuth();
  const [loading, setLoading] = useState(true);

  // Load CR details on component load while editing.
  useEffect(() => {
    // Do nothing once already loaded.
    if (!loading) {
      return;
    }

    if (
      company?.countryCode === "SA" &&
      company?.registrationNumber &&
      !companyDetail
    ) {
      getCompanyFullInfo(
        { cr: company.registrationNumber },
        {
          onSuccess: (data) => {
            if (data) {
              setCompanyDetail(data);
              setLoading(false);
            }
          },
          onError: () => {
            setLoading(false);
          },
          onSettled: () => {
            setLoading(false);
          },
        }
      );
    } else {
      setLoading(false);
    }
  }, [loading]);

  if (loading) {
    return null;
  }

  return (
    <>
      <Stack direction={"column"} spacing={4} justifyContent="space-between">
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {t("pages.verification.subheading")}
        </Typography>

        <Box sx={{ flexGrow: 1 }}>
          {companyDetail ? (
            <KsaForm
              companyDetail={companyDetail}
              countryCode={company?.countryCode}
              clearCurrentCompany={() => setCompanyDetail(null)}
            />
          ) : (
            <VerificationForm
              activeStep={activeStep}
              handleBack={handleBack}
              changeCompanyDetail={(company_detail: any) =>
                setCompanyDetail(company_detail)
              }
            />
          )}
        </Box>
      </Stack>
    </>
  );
};

type CompanyDocumentsType = {
  handleNext: () => void;
  handleBack: () => void;
  activeStep: number;
};
const CompanyDocuments = ({
  handleNext,
  handleBack,
  activeStep,
}: CompanyDocumentsType) => {
  const { company } = useAuth();
  const { t } = useTranslation();
  return (
    <Container
      maxWidth="lg"
      sx={{ my: { xs: 3, sm: 5 }, pr: 0 }}
      disableGutters
    >
      {company?.countryCode === "SA" ? (
        <>
          <Typography variant="h3" sx={{ mb: 1.5 }}>
            {t("pages.verification.heading")}
          </Typography>
          <CompanyDocumentsFormKSA
            activeStep={activeStep}
            handleBack={handleBack}
          />
        </>
      ) : (
        <>
          <Typography variant="h4" sx={{ mb: 1.5 }}>
            {t("pages.verification.company_documents.heading")}
          </Typography>
          <CompanyDocumentsFormSection
            navigation={true}
            handleNext={handleNext}
            activeStep={activeStep}
            handleBack={handleBack}
          />
        </>
      )}
    </Container>
  );
};

export default CompanyDocuments;
