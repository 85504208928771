import useSettings from "../hooks/useSettings";

export const useQuickbooksOAuth = () => {
  const { integrationConfig } = useSettings();

  const initiateQuickbooksOAuth = () => {
    const stateToken = Math.random().toString(36).substring(2);
    const redirect_uri = `${window.origin}/onboarding/connect-systems/custom-fivetran-connector/quickbooks_custom`;
    const queryParams = new URLSearchParams({
      client_id: integrationConfig.quickbooksClientId,
      response_type: "code",
      scope: "com.intuit.quickbooks.accounting",
      redirect_uri: redirect_uri,
      state: stateToken,
    });
    window.location.href = `https://appcenter.intuit.com/connect/oauth2?${queryParams.toString()}`;
  };

  return { initiateQuickbooksOAuth };
};
