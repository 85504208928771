import { lazy, Suspense, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {
  useLocation,
  Navigate,
  Route,
  Routes as ReactRoutes,
} from "react-router-dom";
import { ERAD_CARDS_ENABLED, RECAPTCHA_KEY } from "../../config/config";
import AuthGuard from "../../guards/AuthGuard";
import GuestGuard from "../../guards/GuestGuard";
import OnboardingGuard from "../../guards/OnboardingGuard";
import DeploymentGuard from "../../guards/DeploymentGuard";
import DashboardLayout from "../Layouts/Dashboard";
import LogoOnlyLayout from "../Layouts/LogoOnlyLayout";
import LoadingScreen from "../LoadingScreen";
import OnboardingPages from "../OnboardingPages";
import DeploymentPages from "../DeploymentPages";
import {
  PATH_CAPITAL,
  PATH_CONNECTOR_CALLBACK,
  PATH_INTEGRATION_CALLBACK,
} from "./paths";
import CallbackGuard from "../../guards/CallbackGuard";
import DeploymentSubmitDocuments from "../DeploymentSubmitDocuments";
import DeploymentSignContracts from "../DeploymentSignContracts";
import NotEligibleForFunding from "../VerifyBusinessInfo/NotEligibleForFundingPage";
import ApplicationOverview from "../ApplicationOverview";
import AdditionalDocumentsKSA from "../OnboardingVerificationCountry/AdditionalDocumentsKSA";
import EventRedirect from "../Page/EventRedirect";

export const Loadable =
  (Component: React.LazyExoticComponent<React.ComponentType<any>>) =>
  (props: any) => {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );
  };

const Routes = () => {
  let { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const email = searchParams.get("email");

  useEffect(() => {
    if (email) {
      sessionStorage.setItem("email", email);
    }
  }, [email]);

  const storedEmail = sessionStorage.getItem("email");

  return (
    <ReactRoutes>
      <Route path="eradpay">
        <Route path="" element={<EradPayPayment />} />
        <Route path="callback" element={<EradPayCallback />} />
        <Route
          path="confirmation/success"
          element={<EradPayConfirmationSuccess />}
        />
        <Route
          path="confirmation/failure"
          element={<EradPayConfirmationFailure />}
        />
      </Route>

      <Route element={<GuestGuard />}>
        <Route
          path="confirm-registration/:id/:token"
          element={<ConfirmRegistration />}
        />
        <Route
          path="reset-password-check/:id/:token"
          element={<ResetPasswordCheck />}
        />
        <Route path="e/:eventCode" element={<EventRedirect />} />
      </Route>

      <Route
        element={
          <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey={RECAPTCHA_KEY}
            scriptProps={{ async: true }}
          >
            <GuestGuard>
              <LogoOnlyLayout />
            </GuestGuard>
          </GoogleReCaptchaProvider>
        }
      >
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Register />} />
        <Route path="company-exists" element={<CompanyExists />} />
        <Route
          path="company-exists/requested"
          element={<CompanyExistsRequested />}
        />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route
          path="verify-reset-password-email"
          element={<VerifyResetPasswordEmail />}
        />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route
          path="set-new-password/:id/:token"
          element={<SetNewPassword />}
        />
      </Route>
      <Route
        element={
          <AuthGuard>
            <OnboardingGuard>
              <DeploymentGuard>
                <LogoOnlyLayout />
              </DeploymentGuard>
            </OnboardingGuard>
          </AuthGuard>
        }
      >
        <Route path="verify-phone" element={<VerifyPhone />} />
      </Route>
      <Route
        element={
          <AuthGuard>
            {storedEmail ? (
              <DeploymentGuard>
                <DashboardLayout />
              </DeploymentGuard>
            ) : (
              <OnboardingGuard>
                <DeploymentGuard>
                  <DashboardLayout />
                </DeploymentGuard>
              </OnboardingGuard>
            )}
          </AuthGuard>
        }
      >
        <Route
          element={<Navigate to={PATH_CAPITAL.root} replace />}
          index={true}
        />
        <Route path="tell-about-business" element={<VerifyBusinessInfo />} />
        <Route
          path="connector-redirect-uri/native"
          element={<ConnectorRedirectUriNative />}
        />
        <Route path="onboarding">
          <Route
            element={<Navigate to="/onboarding/connect-systems" replace />}
            index={true}
          />
          <Route element={<OnboardingPages />}>
            <Route
              path="connect-systems"
              element={<OnboardingConnectSystems />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector"
              element={<CustomFivetranConnectorConsentPage />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/connection-test"
              element={<CustomFivetranConnectorConnectionTest />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/amazon-payment_services"
              element={<CustomFivetranConnectorAmazonPaymentServicesForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/daftra"
              element={<CustomFivetranConnectorDaftraForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/facebook-ads_custom"
              element={<CustomFivetranConnectorFacebookAdsForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/google-ads_custom"
              element={<CustomFivetranConnectorGoogleAdsForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/hyperpay"
              element={<CustomFivetranConnectorHyperPayForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/lean"
              element={<CustomFivetranConnectorLeanForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/magento"
              element={<CustomFivetranConnectorMagentoForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/moneyhash"
              element={<CustomFivetranConnectorMoneyHashForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/moneyhash-hp"
              element={<CustomFivetranConnectorMoneyHashHpForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/tappayments"
              element={<CustomFivetranConnectorTapPaymentsForm />}
            />

            <Route
              path="connect-systems/custom-fivetran-connector/tabby"
              element={<CustomFivetranConnectorTabbyForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/checkout"
              element={<CustomFivetranConnectorCheckoutForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/wafeq"
              element={<CustomFivetranConnectorWafeqForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/woocommerce"
              element={<CustomFivetranConnectorWoocommerceForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/wio"
              element={<CustomFivetranConnectorWioForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/zid"
              element={<CustomFivetranConnectorZidForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/zoho"
              element={<CustomFivetranConnectorZohoForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/salla"
              element={<CustomFivetranConnectorSallaForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/snapchat-ads_custom"
              element={<CustomFivetranConnectorSnapchatAdsForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/shopify-custom"
              element={<CustomFivetranConnectorShopifyForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/stripe-custom"
              element={<CustomFivetranConnectorStripeForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/quickbooks_custom"
              element={<CustomFivetranConnectorQuickbooksForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/tiktok-ads_custom"
              element={<CustomFivetranConnectorTiktokAdsForm />}
            />
            <Route
              path="connect-systems/custom-fivetran-connector/xero_custom"
              element={<CustomFivetranConnectorXeroForm />}
            />
            <Route
              path="connect-systems/connector-linked"
              element={<ConnectorLinked />}
            />
            <Route
              path="connect-systems/:connectorTypeCategoryId"
              element={<ConnectorsByCategory />}
            />
            <Route path="verification" element={<OnboardingVerification />} />
            <Route
              path="additional-documents"
              element={<AdditionalDocumentsKSA />}
            />
            <Route
              path="application-overview"
              element={<ApplicationOverview />}
            />
            <Route
              path="not-eligible"
              element={<NotEligibleForFunding summary={true} />}
            />
            <Route path="under-review" element={<OnboardingUnderReview />} />
          </Route>
        </Route>
        <Route path="deployment">
          <Route element={<DeploymentPages />}>
            <Route
              path="accept-offer"
              element={<DeploymentSubmitDocuments />}
            />
            <Route path="submit-kyc" element={<DeploymentSubmitDocuments />} />
            <Route path="sign-contract" element={<DeploymentSignContracts />} />
          </Route>
        </Route>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="capital" element={<Capital />} />
      </Route>

      <Route
        element={
          <AuthGuard>
            <DeploymentGuard>
              <DashboardLayout />
            </DeploymentGuard>
          </AuthGuard>
        }
      >
        <Route
          path="connector-redirect-uri/custom"
          element={<ConnectorRedirectUriCustom />}
        />
        <Route path="statement" element={<History />} />
        <Route path="attachments" element={<Attachments />} />
        <Route path="payments" element={<Payments />}>
          <Route
            element={<Navigate to="/payments/transactions" replace />}
            index={true}
          />
          <Route path="charge" element={<PaymentsCharge />} />
          <Route path="subscription" element={<PaymentsSubscription />} />
          <Route path="transactions" element={<PaymentsListTransactions />} />
          <Route path="refunds" element={<PaymentsListRefunds />} />
          <Route path="settlements" element={<PaymentsListSettlements />} />
        </Route>
        {ERAD_CARDS_ENABLED && (
          <Route path="cards" element={<EradCards />}>
            <Route
              element={<Navigate to="/cards/details" replace />}
              index={true}
            />
            <Route path="details" element={<EradCardsDetail />} />
            <Route path="transactions" element={<EradCardsTransactions />} />
          </Route>
        )}
        {/*<Route path="cards" element={<CardsRequest />} />*/}
        <Route path="settings" element={<Settings />}>
          <Route
            element={<Navigate to="/settings/account" replace />}
            index={true}
          />
          <Route path="account" element={<AccountSettings />} />
          <Route path="company" element={<CompanySettings />} />
          <Route path="data-sources" element={<DataSourcesSettings />} />
          <Route path="team" element={<TeamSettings />} />
          <Route
            path="data-sources/add-new-connector"
            element={<DataSourcesAddNewConnector />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector"
            element={<CustomFivetranConnectorConsentPage />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/connection-test"
            element={<CustomFivetranConnectorConnectionTest />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/amazon-payment_services"
            element={<CustomFivetranConnectorAmazonPaymentServicesForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/daftra"
            element={<CustomFivetranConnectorDaftraForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/facebook-ads_custom"
            element={<CustomFivetranConnectorFacebookAdsForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/google-ads_custom"
            element={<CustomFivetranConnectorGoogleAdsForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/hyperpay"
            element={<CustomFivetranConnectorHyperPayForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/lean"
            element={<CustomFivetranConnectorLeanForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/magento"
            element={<CustomFivetranConnectorMagentoForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/moneyhash"
            element={<CustomFivetranConnectorMoneyHashForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/moneyhash-hp"
            element={<CustomFivetranConnectorMoneyHashHpForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/wafeq"
            element={<CustomFivetranConnectorWafeqForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/woocommerce"
            element={<CustomFivetranConnectorWoocommerceForm />}
          />
          <Route
            path="connect-systems/custom-fivetran-connector/wio"
            element={<CustomFivetranConnectorWioForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/tappayments"
            element={<CustomFivetranConnectorTapPaymentsForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/tabby"
            element={<CustomFivetranConnectorTabbyForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/checkout"
            element={<CustomFivetranConnectorCheckoutForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/zid"
            element={<CustomFivetranConnectorZidForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/zoho"
            element={<CustomFivetranConnectorZohoForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/salla"
            element={<CustomFivetranConnectorSallaForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/snapchat-ads_custom"
            element={<CustomFivetranConnectorSnapchatAdsForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/shopify-custom"
            element={<CustomFivetranConnectorShopifyForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/stripe-custom"
            element={<CustomFivetranConnectorStripeForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/quickbooks_custom"
            element={<CustomFivetranConnectorQuickbooksForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/tiktok-ads_custom"
            element={<CustomFivetranConnectorTiktokAdsForm />}
          />
          <Route
            path="data-sources/add-new-connector/custom-fivetran-connector/xero_custom"
            element={<CustomFivetranConnectorXeroForm />}
          />
          <Route
            path="data-sources/add-new-connector/connector-linked"
            element={<ConnectorLinked />}
          />
          <Route
            path="data-sources/add-new-connector/:connectorTypeCategoryId"
            element={<ConnectorsByCategory />}
          />
        </Route>
      </Route>

      <Route
        element={
          <CallbackGuard>
            <LogoOnlyLayout />
          </CallbackGuard>
        }
      >
        <Route
          path={PATH_CONNECTOR_CALLBACK.salla}
          element={<ConnectorCallbackSalla />}
        />
        <Route
          path={PATH_INTEGRATION_CALLBACK.direct}
          element={<ConnectorCallbackGeneral />}
        />
      </Route>

      <Route path={"*"} element={<LogoOnlyLayout />}>
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>

      <Route path={"*"} element={<Navigate to="/404" replace />} />
    </ReactRoutes>
  );
};

const EradPayPayment = Loadable(lazy(() => import("../EradPay")));
const EradPayCallback = Loadable(lazy(() => import("../EradPay/Callback")));
const EradPayConfirmationSuccess = Loadable(
  lazy(() => import("../EradPayConfirmation/Success"))
);
const EradPayConfirmationFailure = Loadable(
  lazy(() => import("../EradPayConfirmation/Failure"))
);
const Login = Loadable(lazy(() => import("../Login")));
const Register = Loadable(lazy(() => import("../Register")));
const CompanyExists = Loadable(lazy(() => import("../CompanyExists")));
const CompanyExistsRequested = Loadable(
  lazy(() => import("../CompanyExistsRequested"))
);
const VerifyEmail = Loadable(lazy(() => import("../VerifyEmail")));
const VerifyPhone = Loadable(lazy(() => import("../VerifyPhone")));
const VerifyBusinessInfo = Loadable(
  lazy(() => import("../VerifyBusinessInfo"))
);
const ConfirmRegistration = Loadable(
  lazy(() => import("../ConfirmRegistration"))
);
const OnboardingConnectSystems = Loadable(
  lazy(() => import("../OnboardingConnectSystems"))
);
const OnboardingVerification = Loadable(
  lazy(() => import("../OnboardingVerificationCountry"))
);
const OnboardingUnderReview = Loadable(
  lazy(() => import("../OnboardingUnderReview"))
);
const ResetPassword = Loadable(lazy(() => import("../ResetPassword")));
const VerifyResetPasswordEmail = Loadable(
  lazy(() => import("../VerifyResetPasswordEmail"))
);
const ResetPasswordCheck = Loadable(
  lazy(() => import("../ResetPasswordCheck"))
);
const SetNewPassword = Loadable(lazy(() => import("../SetNewPassword")));
const Capital = Loadable(lazy(() => import("../Capital")));
const History = Loadable(lazy(() => import("../History")));
const Attachments = Loadable(lazy(() => import("../Attachments/Form")));
const Payments = Loadable(lazy(() => import("../Payments")));
const PaymentsCharge = Loadable(lazy(() => import("../PaymentsCharge")));
const PaymentsSubscription = Loadable(
  lazy(() => import("../PaymentsSubscription"))
);
const PaymentsListTransactions = Loadable(
  lazy(() => import("../PaymentsList/Transactions"))
);
const PaymentsListRefunds = Loadable(
  lazy(() => import("../PaymentsList/Refunds"))
);
const PaymentsListSettlements = Loadable(
  lazy(() => import("../PaymentsList/Settlements"))
);

// erad Cards
const EradCards = Loadable(lazy(() => import("../EradCards")));
const EradCardsDetail = Loadable(lazy(() => import("../EradCards/Detail")));
const EradCardsTransactions = Loadable(
  lazy(() => import("../EradCards/Transactions"))
);

const Settings = Loadable(lazy(() => import("../Settings")));
const Dashboard = Loadable(lazy(() => import("../CapitalDashboard")));
const AccountSettings = Loadable(lazy(() => import("../AccountSettings")));
const CompanySettings = Loadable(lazy(() => import("../CompanySettings")));
const DataSourcesSettings = Loadable(
  lazy(() => import("../DataSourcesSettings"))
);
const TeamSettings = Loadable(lazy(() => import("../TeamSettings")));
const DataSourcesAddNewConnector = Loadable(
  lazy(() => import("../DataSourcesAddNewConnector"))
);
const ConnectorsByCategory = Loadable(
  lazy(() => import("../ConnectorsByCategory"))
);
const CustomFivetranConnectorConsentPage = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/ConsentPage"))
);
const CustomFivetranConnectorConnectionTest = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/ConnectionTest"))
);
const CustomFivetranConnectorAmazonPaymentServicesForm = Loadable(
  lazy(
    () =>
      import("../Connectors/CustomFivetranConnector/AmazonPaymentServices/Form")
  )
);
const CustomFivetranConnectorDaftraForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Daftra/Form"))
);
const CustomFivetranConnectorFacebookAdsForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/FacebookAds/Form"))
);
const CustomFivetranConnectorGoogleAdsForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/GoogleAds/Form"))
);
const CustomFivetranConnectorHyperPayForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/HyperPay/Form"))
);
const CustomFivetranConnectorLeanForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Lean/Form"))
);
const CustomFivetranConnectorMagentoForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Magento/Form"))
);
const CustomFivetranConnectorTapPaymentsForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/TapPayments/Form"))
);
const CustomFivetranConnectorCheckoutForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Checkout/Form"))
);
const CustomFivetranConnectorWafeqForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Wafeq/Form"))
);
const CustomFivetranConnectorWoocommerceForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/WooCommerce/Form"))
);
const CustomFivetranConnectorZidForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Zid/Form"))
);
const CustomFivetranConnectorZohoForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Zoho/Form"))
);
const CustomFivetranConnectorSallaForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Salla/Form"))
);
const CustomFivetranConnectorSnapchatAdsForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/SnapchatAds/Form"))
);
const CustomFivetranConnectorShopifyForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Shopify/Form"))
);
const CustomFivetranConnectorStripeForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Stripe/Form"))
);
const CustomFivetranConnectorTabbyForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Tabby/Form"))
);
const CustomFivetranConnectorWioForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Wio/Form"))
);
const CustomFivetranConnectorMoneyHashForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/MoneyHash/Form"))
);
const CustomFivetranConnectorMoneyHashHpForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/MoneyHashHp/Form"))
);
const CustomFivetranConnectorQuickbooksForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Quickbooks/Form"))
);
const CustomFivetranConnectorTiktokAdsForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/TiktokAds/Form"))
);
const CustomFivetranConnectorXeroForm = Loadable(
  lazy(() => import("../Connectors/CustomFivetranConnector/Xero/Form"))
);
const ConnectorLinked = Loadable(lazy(() => import("../ConnectorLinked")));
const ConnectorRedirectUriNative = Loadable(
  lazy(() => import("../ConnectorRedirectUriNative"))
);
const ConnectorRedirectUriCustom = Loadable(
  lazy(() => import("../ConnectorRedirectUriCustom"))
);
const ConnectorCallbackSalla = Loadable(
  lazy(() => import("../ConnectorCallbackSalla"))
);
const ConnectorCallbackGeneral = Loadable(
  lazy(() => import("../IntegrationMiddleProcessor"))
);
const NotFound = Loadable(lazy(() => import("../Page404")));

export default Routes;
