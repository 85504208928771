import React from "react";
import { useFormikContext } from "formik";
import ReactPhoneInput, { CountryData } from "react-phone-input-material-ui";
import { FormHelperText, TextField as MuiTextField } from "@mui/material";
import useSettings from "../../hooks/useSettings";
import { useTranslation } from "react-i18next";
import "react-phone-input-material-ui/lib/style.css";
import ar from "react-phone-input-material-ui/lang/ar.json";
import { isLngAR } from "../../utils/i18n";
import { exists } from "i18next";

type FormikPhoneNumberType = {
  phoneNumber: string;
  phoneCode: string;
  phoneCodeDial?: string;
  required?: boolean;
  isDisabled?: boolean;
};

const FormikPhoneNumber = <T extends FormikPhoneNumberType>({
  required = true,
  isDisabled = false,
}) => {
  const { phoneCountries } = useSettings();
  const { t } = useTranslation();
  const { values, handleBlur, setFieldValue, isSubmitting, touched, errors } =
    useFormikContext<T>();

  return (
    <>
      <ReactPhoneInput
        value={values.phoneNumber}
        inputProps={{
          autoComplete: "phone-number-nope",
          id: "field-phone-number",
          name: "phoneNumber",
          variant: "standard",
          label: t("fields.phone_number"),
          required,
        }}
        containerStyle={{ direction: "ltr" }}
        component={MuiTextField}
        disabled={isSubmitting || isDisabled}
        country={values.phoneCode}
        onlyCountries={phoneCountries}
        localization={isLngAR() ? ar : exists}
        onBlur={handleBlur}
        onChange={(phone: string, country: CountryData) => {
          setFieldValue("phoneCode", country.countryCode.toUpperCase());
          setFieldValue("phoneCodeDial", country.dialCode);
          setFieldValue("phoneNumber", "+" + phone);
        }}
        dropdownStyle={{ height: "135px" }}
      />
      {touched["phoneNumber"] && errors["phoneNumber"] && (
        <FormHelperText error={true}>{errors["phoneNumber"]}</FormHelperText>
      )}
    </>
  );
};

export default FormikPhoneNumber;
