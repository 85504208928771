import { Alert } from "@mui/material";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

type CustomConnectorErrorProps = {
  show: boolean;
  handleShow: () => void;
  error?: string;
  errorMessage?: string;
};

const CustomConnectorError = ({
  show,
  handleShow,
  error = "",
  errorMessage = "",
}: CustomConnectorErrorProps) => {
  const { t } = useTranslation();
  const myRef = useRef<HTMLDivElement | null>(null);
  const errorText = error || t("error.connectors.custom_fivetran.connecting");

  setTimeout(() => {
    myRef.current?.scrollIntoView();
  }, 10);

  return show ? (
    <Alert
      ref={myRef}
      onClose={() => {
        handleShow();
      }}
      variant="outlined"
      severity="error"
      sx={{ mb: 2, backgroundColor: "white" }}
    >
      {errorMessage ? errorMessage : errorText}
    </Alert>
  ) : (
    <></>
  );
};

export default CustomConnectorError;
