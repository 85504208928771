import {
  useMutation,
  UseMutationResult,
  useQueries,
  useQuery,
  UseQueryResult,
} from "react-query";
import { PrivateFile } from "../types/PrivateFile";
import { fetch } from "../utils/dataAccess";
import ViolationError from "../utils/violationError";

interface UploadPrivateFile {
  file: File;
  requestConfig?: {};
}

export const uploadPrivateFile = async ({
  file,
  requestConfig,
}: UploadPrivateFile): Promise<PrivateFile> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  const { data } = await fetch({
    url: "/private_files",
    method: "POST",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
    ...requestConfig,
  });

  return data;
};

export const useUploadPrivateFileMutation = (): UseMutationResult<
  PrivateFile,
  Error | ViolationError,
  UploadPrivateFile
> => {
  return useMutation(uploadPrivateFile, { mutationKey: "privateFileUpload" });
};

interface UploadPrivateFileAttachment {
  file: File;
  fileName?: string | undefined;
  fileCategory?: string | undefined;
  requestConfig?: {};
}

export const uploadPrivateFileAttachment = async ({
  file,
  fileName,
  fileCategory,
  requestConfig,
}: UploadPrivateFileAttachment): Promise<PrivateFile> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);
  bodyFormData.append("fileName", fileName || "");
  bodyFormData.append("fileCategory", fileCategory || "");

  const { data } = await fetch({
    url: "/private_files",
    method: "POST",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
    ...requestConfig,
  });

  return data;
};

export const useUploadPrivateFileAttachmentMutation = (): UseMutationResult<
  PrivateFile,
  Error | ViolationError,
  UploadPrivateFileAttachment
> => {
  return useMutation(uploadPrivateFileAttachment, {
    mutationKey: "privateFileUpload",
  });
};

export const getPrivateFile = async (
  privateFile: string
): Promise<PrivateFile> => {
  const { data } = await fetch({ url: privateFile });
  return data;
};

export const usePrivateFileQuery = (
  privateFile: string
): UseQueryResult<PrivateFile, Error> => {
  return useQuery(
    ["getPrivateFile", privateFile],
    () => getPrivateFile(privateFile),
    {
      enabled: !!privateFile,
    }
  );
};

export const usePrivateFilesQuery = (
  privateFiles: Array<string>
): UseQueryResult<PrivateFile, unknown>[] => {
  return useQueries(
    privateFiles.map((privateFile) => {
      return {
        queryKey: ["getPrivateFile", privateFile],
        queryFn: () => getPrivateFile(privateFile),
      };
    })
  );
};

interface UpdateFilePassword {
  file: PrivateFile;
  password: string;
}

export const updateFilePassword = async ({
  file,
  password,
}: UpdateFilePassword): Promise<PrivateFile> => {
  const { data } = await fetch({
    url: `${file["@id"]}/set-password`,
    method: "PUT",
    data: { password },
  });

  return data;
};

export const useUpdateFilePasswordMutation = (): UseMutationResult<
  PrivateFile,
  Error | ViolationError,
  UpdateFilePassword
> => {
  return useMutation(updateFilePassword, { mutationKey: "updateFilePassword" });
};

interface UpdatePasswordEnabled {
  file: PrivateFile;
  passwordEnabled: boolean;
}

export const updatePasswordEnabled = async ({
  file,
  passwordEnabled,
}: UpdatePasswordEnabled): Promise<PrivateFile> => {
  const { data } = await fetch({
    url: `${file["@id"]}/set-password-enabled`,
    method: "PUT",
    data: { passwordEnabled },
  });

  return data;
};

export const useUpdatePasswordEnabledMutation = (): UseMutationResult<
  PrivateFile,
  Error | ViolationError,
  UpdatePasswordEnabled
> => {
  return useMutation(updatePasswordEnabled, {
    mutationKey: "updatePasswordEnabled",
  });
};
