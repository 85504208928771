import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Collapse,
} from "@mui/material";
import { Form, Formik } from "formik";
import { UploadMultiFile } from "../Upload";
import { PrivateFile } from "../../types/PrivateFile";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { isLngAR } from "../../utils/i18n";
import { DEFAULT_ALLOWED_MIME_TYPES } from "../../config/config";
import { useState } from "react";
import { useKycKybPost } from "../../queries/kycKyb";
import useAuth from "../../hooks/useAuth";
import { KycKybDocumentsValues } from "../../types/FormValues";
import { KycKybValues } from "../OnboardingVerification/KycKybForm";
import ViolationError from "../../utils/violationError";
import FormikAutoSubmit from "../FormikAutoSubmit";
import { useIsMobile } from "../../hooks/useIsMobile";

export const connectorBoxStyles = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const connectorBoxImageStyles = {
  display: "block",
  maxWidth: "67px",
  maxHeight: "67px",
};

type BusinessPremisesFormSectionProps = {
  navigation: boolean;
  handleNext?: any;
  activeStep?: any;
  handleBack?: any;
};

export const BusinessPremisesFormSection = ({
  navigation,
  handleNext,
  activeStep,
  handleBack,
}: BusinessPremisesFormSectionProps) => {
  const { company, refetchCompany } = useAuth();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { mutate: kycKybPost } = useKycKybPost();
  const { user } = useAuth();
  const currentDocs = (company?.kycKybDocuments?.businessPremises ??
    []) as PrivateFile[];
  const [isCsvConnectorExpanded, setIsCsvConnectorExpanded] = useState(
    currentDocs.length > 0
  );

  const isContinue = (values: { businessPremises: PrivateFile[] }) => {
    return values.businessPremises.length > 0 || currentDocs.length;
  };

  return (
    <>
      {company?.revenueSegments?.includes("clinic") ? (
        <>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {t("pages.verification.business_premises.subheading_clinic")}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {t("pages.verification.business_premises.subheading")}
          </Typography>
        </>
      )}

      <Box mt={4}>
        <Formik
          initialValues={{
            businessPremises: currentDocs,
          }}
          validationSchema={Yup.object().shape({
            businessPremises: Yup.array().mime(
              Object.keys(DEFAULT_ALLOWED_MIME_TYPES),
              t("error.general.mime", {
                types: Object.keys(DEFAULT_ALLOWED_MIME_TYPES).concat(", "),
              })
            ),
          })}
          onSubmit={(values, { setStatus, setErrors, setSubmitting }) => {
            const filteredValues = {} as KycKybDocumentsValues;
            for (const [documentGroup, documents] of Object.entries(values)) {
              filteredValues[documentGroup as keyof KycKybValues] =
                documents.map((document: PrivateFile) => document["@id"]!);
            }

            kycKybPost(
              { companyIri: user?.company!, values: filteredValues },
              {
                onError: (error) => {
                  if (error instanceof ViolationError) {
                    setStatus({
                      isValid: false,
                      msg: `${error.defaultErrorMsg}`,
                    });
                    setErrors(error.fields);
                  } else {
                    setStatus({
                      isValid: false,
                      msg: `${error.message}`,
                    });
                  }

                  setSubmitting(false);
                },
                onSuccess: () => {
                  refetchCompany();
                  setSubmitting(false);
                },
              }
            );
          }}
        >
          {({ values, errors, isSubmitting, isValid, status }) => (
            <Form>
              <Box>
                <Grid item xs={6} key={"csv-upload"}>
                  <Box
                    flexDirection="row"
                    onClick={() =>
                      setIsCsvConnectorExpanded(
                        (isCsvConnectorExpanded) => !isCsvConnectorExpanded
                      )
                    }
                    sx={{
                      ...connectorBoxStyles,
                      borderWidth: "1px",
                      m: "1px",
                      height: "80px",
                      borderStyle: "solid",
                      borderColor: (theme) =>
                        theme.palette.action.disabledBackground,
                      borderRadius: "8px",
                      cursor: "pointer",
                      "&:hover, &:focus": {
                        borderWidth: "1px",
                        borderColor: (theme) => theme.palette.primary.main,
                        backgroundColor: "#1976D20A",
                      },
                      marginBottom: 1,
                    }}
                  >
                    <Box
                      component="img"
                      src={`/images/connector-types/file-upload.png`}
                      alt="Connector type logo"
                      height={"45px"}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        mt: 1.25,
                        ml: "20px",
                        fontSize: { xs: "80%", sm: "100%" },
                      }}
                    >
                      {t("connectors.csv_connector.upload_files")}
                    </Typography>
                  </Box>
                </Grid>
                <Collapse in={isCsvConnectorExpanded}>
                  <UploadMultiFile
                    sxWrapper={{ mt: 1.5 }}
                    fieldName="businessPremises"
                    disabled={isSubmitting}
                    maxFileSize={104857600}
                    filesLimit={20}
                  />
                </Collapse>
              </Box>
              <FormikAutoSubmit debounceMs={1000} />
              {navigation && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 5,
                    gap: isMobile ? 2 : "auto",
                  }}
                >
                  <Button
                    color="inherit"
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t("global.back")}
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    type="submit"
                    onClick={handleNext}
                    variant="outlined"
                    endIcon={
                      isLngAR() ? <ArrowBackIcon /> : <ArrowForwardIcon />
                    }
                    sx={{
                      width: { xs: "100%", sm: "inherit" },
                    }}
                  >
                    {isContinue(values)
                      ? t("global.continue")
                      : t("global.skip")}
                  </Button>
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

type BusinessPremisesType = {
  handleNext: () => void;
  handleBack: () => void;
  activeStep: number;
};
const BusinessPremises = ({
  handleNext,
  handleBack,
  activeStep,
}: BusinessPremisesType) => {
  const { company } = useAuth();
  const { t } = useTranslation();
  return (
    <Container
      maxWidth="lg"
      sx={{ my: { xs: 3, sm: 5 }, pr: 0 }}
      disableGutters
    >
      {company?.revenueSegments?.includes("clinic") ? (
        <>
          <Typography variant="h4" sx={{ mb: 1.5 }}>
            {t("pages.verification.business_premises.heading_clinic")}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h4" sx={{ mb: 1.5 }}>
            {t("pages.verification.business_premises.heading")}
          </Typography>
        </>
      )}

      <BusinessPremisesFormSection
        navigation={true}
        handleNext={handleNext}
        activeStep={activeStep}
        handleBack={handleBack}
      />
    </Container>
  );
};

export default BusinessPremises;
