import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function AttachmentsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 4.72992V4.49463C7 3.39006 7.89543 2.49463 9 2.49463H19C20.1046 2.49463 21 3.39006 21 4.49463V16.4946C21 17.5992 20.1046 18.4946 19 18.4946V18.4946"
          stroke="currentColor"
          stroke-width="2"
        />
        <rect
          x="3"
          y="6.49463"
          width="14"
          height="16"
          rx="2"
          stroke="currentColor"
          stroke-width="2"
        />
        <path d="M6 10.4946H12" stroke="currentColor" stroke-width="2" />
        <path d="M6 14.4946H14" stroke="currentColor" stroke-width="2" />
        <path d="M6 18.4946H14" stroke="currentColor" stroke-width="2" />
      </svg>
    </SvgIcon>
  );
}
