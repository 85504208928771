import { useFormikContext } from "formik";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo } from "react";

type FormikAutoSubmitProps = {
  debounceMs: number;
};

// Component that will auto submit a formik form after debounce milliseconds.
const FormikAutoSubmit = ({ debounceMs }: FormikAutoSubmitProps) => {
  const formik = useFormikContext();

  const debouncedFunc = useMemo(
    () => debounce(formik.handleSubmit, debounceMs),
    [formik.handleSubmit, debounceMs]
  );
  const debouncedSubmit = useCallback(() => debouncedFunc(), [debouncedFunc]);

  useEffect(() => {
    if (formik.values !== formik.initialValues) {
      debouncedSubmit();
    }
  }, [debouncedSubmit, formik.values, formik.initialValues]);

  return null;
};

export default FormikAutoSubmit;
