import { GOOGLE_ADS_CLIENT_ID } from "../config/connector";

export const initiateGoogleOAuth = () => {
  const queryParams = new URLSearchParams({
    response_type: "code",
    client_id: GOOGLE_ADS_CLIENT_ID,
    scope: "https://www.googleapis.com/auth/adwords",
    access_type: "offline",
  });
  const redirect_uri = `${window.origin}/onboarding/connect-systems/custom-fivetran-connector/google-ads_custom`;
  window.location.href = `https://accounts.google.com/o/oauth2/auth?${queryParams.toString()}&redirect_uri=${redirect_uri}&prompt=select_account+consent`;
};
